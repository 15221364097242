import { useLazyQuery } from "@apollo/client";
import { GET_MODELS } from "components/gql/gql_queries";
import { consoleBeauty, logGQLError } from "data/functions";

export function GetModelsQuery(markID) {
  const queryName = "GetModels";
  const [refetch, variables] = useLazyQuery(GET_MODELS, {
    errorPolicy: "all",
    fetchPolicy: "no-cache",
    skip: !markID,
    variables: {
      markId: markID,
    },
    onCompleted: () => {
      consoleBeauty("Получен список моделей", "GraphQL", "darkgreen");
    },
    onError: (e) => {
      logGQLError(queryName, e);
    },
  });
  return [refetch, variables];
}
