import { gql } from "@apollo/client";

export const FIND_CLIENT = gql`
  query FindClient($phone: String, $companyUuid: String) {
    findClient(phone: $phone, companyUUID: $companyUuid) {
      id
      name
    }
  }
`;

export const GET_CAR_LIST = gql`
  query CarsList($userId: String) {
    export_carsList(userID: $userId, includeSold: true) {
      sold
      regNum
      lastVisitAt
      lastMileage
      car {
        id
        color
        marka {
          id
          name
          imageUrl
        }
        model {
          id
          name
          imageUrl
        }
        vin
        year
      }
    }
  }
`;

export const CAR_RECOMMENDATIONS_LIST = gql`
  query RecommendationsList($carId: String!, $userId: String!) {
    export_recommendationsList(carID: $carId, userID: $userId) {
      id
      text
      urgency
      createdAt
    }
  }
`;

export const GET_SELF_DATA = gql`
  query GetSelfData($token: String) {
    auth_getSelfData(token: $token) {
      clientID
      companyUUID
    }
  }
`;

export const GIBDD_UPLOAD_URL = gql`
  query UploadUrlQuery($filename: String!) {
    gibdd_uploadUrl(filename: $filename) {
      xAmxTagging
      url
      method
      fileID
    }
  }
`;
export const EXPORT_UPLOAD_URL = gql`
  query Export_uploadUrl(
    $companyUuid: String!
    $filename: String!
    $prefix: String
  ) {
    export_uploadUrl(
      companyUUID: $companyUuid
      filename: $filename
      prefix: $prefix
    ) {
      fileID
      url
      method
      xAmxTagging
    }
  }
`;

export const GET_MODEL = gql`
  query Exch_getModel($markId: String!, $modelId: String!) {
    exch_getModel(markID: $markId, modelID: $modelId) {
      id
      markId
      name
      presignURL {
        fileID
        method
        url
        xAmxTagging
      }
    }
  }
`;

export const GET_SELF_INFO = gql`
  query Export_userRead($companyUuid: String!, $userId: String) {
    export_userRead(companyUUID: $companyUuid, userID: $userId) {
      id
      name {
        firstName
        middleName
        lastName
        fullName
      }
      birthday
      email
      phone
      sex
      employee
      imageUrls
      telegram
    }
  }
`;

export const REJECT_RECOMMENDATIONS = gql`
  mutation RecommendationReject($input: Rejection!) {
    export_recommendationReject(input: $input) {
      code
      success
      message
      result
    }
  }
`;

export const GET_DEPARTMENT_LIST = gql`
  query CompanyDepartments($companyUuid: String!) {
    export_companyDepartments(companyUUID: $companyUuid) {
      departments {
        ID
        name
        workTime
        adress
        locationLatitude
        locationLongitude
        phone
        region
      }
      regions {
        name
      }
    }
  }
`;

export const GET_COMPANY_CHANNELS = gql`
  query Acc_getCompanyChannels($companyUuid: String!) {
    acc_getCompanyChannels(companyUUID: $companyUuid) {
      name
      active
    }
  }
`;

export const SERVICES_LIST = gql`
  query ServicesList(
    $companyUuid: String!
    $categoryId: String
    $serviceId: String
    $recommended: Boolean
  ) {
    export_servicesList(
      companyUUID: $companyUuid
      categoryID: $categoryId
      serviceID: $serviceId
      recommended: $recommended
    ) {
      categories {
        parentId
        id
        name
        hasChildren
        image
      }
      services {
        parentId
        id
        name
        description
        fullDescription
        image
      }
      flowId
    }
  }
`;

export const SERVICE_PRICE = gql`
  query ServicePrices(
    $companyUuid: String!
    $markaId: String
    $modelId: String
    $serviceId: [String!]
  ) {
    export_servicePrices(
      companyUUID: $companyUuid
      markaID: $markaId
      modelID: $modelId
      serviceID: $serviceId
    ) {
      price
      priceType
      salePrice
      service {
        id
        name
        description
      }
    }
  }
`;
export const SEARCH_SERVICE = gql`
  query svcSearch(
    $companyUuid: String!
    $searchText: String!
    $flowId: String
  ) {
    export_svcSearch(
      companyUUID: $companyUuid
      searchText: $searchText
      flowID: $flowId
    ) {
      categories {
        parentId
        id
        name
        hasChildren
        image
      }
      services {
        parentId
        id
        name
        description
        fullDescription
        image
      }
      flowId
    }
  }
`;

export const ORDERS_LIST = gql`
  query OrdersList {
    export_ordersList {
      departmentID
      description
      docDate
      docNumber
      docSum {
        operations
        parts
        total
      }
      masterName
      mileage
      operations {
        count
        discount
        name
        sum
      }
      orderID
      parts {
        count
        discount
        name
        sum
      }
      status
      type
      planDate
      carID
      payPaid
      payWaiting
      payDebt
      payStatus
    }
  }
`;

export const ORDER_READ = gql`
  query OrderRead($orderId: String!) {
    export_orderRead(orderID: $orderId) {
      carID
      departmentID
      description
      docDate
      docNumber
      docSum {
        operations
        parts
        total
      }
      masterName
      mileage
      operations {
        count
        discount
        name
        sum
      }
      orderID
      parts {
        count
        discount
        name
        sum
      }
      status
      type
      planDate
      payPaid
      payWaiting
      payDebt
      payStatus
    }
  }
`;

export const CAR_UPDATE = gql`
  mutation CarUpdate($input: CarUpdateInput!) {
    export_carUpdate(input: $input) {
      car {
        id
        color
        marka {
          id
          name
          imageUrl
        }
        model {
          id
          name
          imageUrl
        }
        vin
        year
      }
      lastMileage
      lastVisitAt
      regNum
      sold
    }
  }
`;

export const REJECT_REASONS = gql`
  query RejectReasons {
    export_rejectReasons {
      id
      name
    }
  }
`;

export const GET_CREDITS = gql`
  query CreditBalance($userId: String) {
    export_creditBalance(userID: $userId) {
      activeCreditsTotal
      credits {
        activeCredit
        inactiveCredit
        lifePeriod {
          expireDate
          activateDate
        }
      }
      inactiveCreditsTotal
      presents {
        name
        lifePeriod {
          expireDate
          activateDate
        }
        id
      }
    }
  }
`;

export const ADD_NEW_DEAL = gql`
  mutation DealAdd($input: DealAdd!) {
    export_dealAdd(input: $input) {
      code
      success
      message
      result {
        dealDate
        dealID
        dealNumber
      }
    }
  }
`;

export const CREDIT_HISTORY = gql`
  query CreditHistory($userId: String, $companyUuid: String) {
    export_creditHistory(userID: $userId, companyUUID: $companyUuid) {
      activeCredits
      date
    }
  }
`;

export const USER_UPDATE = gql`
  mutation Mutation($input: UserUpdateInput!) {
    export_userUpdate(input: $input) {
      success
      message
      code
      user {
        id
        name {
          firstName
          middleName
          lastName
          fullName
        }
        birthday
        email
        phone
        sex
      }
    }
  }
`;

export const UPDATE_COMPANY_STYLE = gql`
  mutation Acc_setCompanyExtData(
    $input: CompanyExtDataInput!
    $companyUuid: String!
  ) {
    acc_setCompanyExtData(input: $input, companyUUID: $companyUuid) {
      code
      success
      message
      data {
        title
        styles {
          favicon
          logoMain
          logoSecond
        }
        styleUrls {
          favicon {
            fileID
            url
            method
            xAmxTagging
          }
          logoMain {
            fileID
            url
            method
            xAmxTagging
          }
          logoSecond {
            fileID
            url
            method
            xAmxTagging
          }
        }
        loyalty {
          used
          description
        }
        metrika {
          yandex
        }
      }
    }
  }
`;

export const GET_COMPANY_STYLE = gql`
  query Acc_getCompanyExtData($companyUuid: String!) {
    acc_getCompanyExtData(companyUUID: $companyUuid) {
      title
      styles {
        favicon
        logoMain
        logoSecond
      }
      styleUrls {
        favicon {
          fileID
          url
          method
          xAmxTagging
        }
        logoMain {
          fileID
          url
          method
          xAmxTagging
        }
        logoSecond {
          fileID
          url
          method
          xAmxTagging
        }
      }
      loyalty {
        used
        description
      }
      metrika {
        yandex
      }
    }
  }
`;

export const FLOW_INIT = gql`
  query GetExtFlowInit(
    $companyUuid: String!
    $channel: ChannelType!
    $phone: String
  ) {
    auth_getExtFlowInit(
      companyUUID: $companyUuid
      channel: $channel
      phone: $phone
    ) {
      codeLength
      expiresIn
      flowID
      telegramURL
    }
  }
`;

export const EXT_LOGIN = gql`
  mutation ExtLogIn($input: ExtLogInInput!) {
    auth_extLogIn(input: $input) {
      code
      success
      message
      data {
        accessToken
        expiresIn
        refreshToken
        refreshExpiresIn
      }
    }
  }
`;

export const EXT_LOGOUT = gql`
  mutation ExtLogOut {
    auth_extLogOut {
      code
      success
      message
    }
  }
`;

export const EXT_REFRESH_TOKEN = gql`
  mutation Auth_extRefreshToken($input: ExtRefreshTokenInput!) {
    auth_extRefreshToken(input: $input) {
      code
      success
      message
      data {
        accessToken
        expiresIn
        refreshToken
        refreshExpiresIn
      }
    }
  }
`;

export const OIDC_LOGIN = gql`
  mutation Auth_oidcLogIn($input: OidcLogInInput!) {
    auth_oidcLogIn(input: $input) {
      code
      success
      message
      data {
        accessToken
        expiresIn
        refreshToken
        refreshExpiresIn
      }
    }
  }
`;

export const GET_MARKS = gql`
  query getMarks($orderBy: MarksOrderBy) {
    exch_getMarks(orderBy: $orderBy, size: 9999, page: 1) {
      marks {
        id
        name
        shortName
      }
    }
  }
`;

export const GET_MARK = gql`
  query Exch_getMark($markId: String!) {
    exch_getMark(markID: $markId) {
      id
      name
      shortName
      objectId
      presignURL {
        fileID
        method
        url
        xAmxTagging
      }
    }
  }
`;

export const GET_MODELS = gql`
  query getModels($markId: String!) {
    exch_getModels(markID: $markId, size: 9999, page: 1) {
      models {
        id
        name
        yearFrom
        yearTo
        markId
      }
    }
  }
`;

export const DECODE_VIN = gql`
  mutation Exch_decodeVin($vin: String!) {
    exch_decodeVin(vin: $vin) {
      code
      success
      message
      data {
        manufacturers {
          id
          name
          shortName
        }
        models {
          id
          name
          yearFrom
          yearTo
          markId
        }
      }
    }
  }
`;

export const DECODE_PLATE = gql`
  query CarReport($grz: String!, $companyUuid: String) {
    gibdd_carReport(GRZ: $grz, CompanyUUID: $companyUuid) {
      vin
      sts
      grz
      brandOriginal
      brandNormalized
      year
      engineFuelType
      enginePowerHp
      enginePowerKw
    }
  }
`;

export const ADD_CAR = gql`
  mutation CarAdd($input: CarAddInput!) {
    export_carAdd(input: $input) {
      code
      success
      message
    }
  }
`;

export const SEARCH_DNS = gql`
  mutation Acc_searchDnsHostLink($host: String!) {
    acc_searchDnsHostLink(host: $host) {
      code
      success
      message
      data {
        companyUUID
        companyName
        cName
        subDomain
      }
    }
  }
`;

export const FILE_UPLOAD = gql`
  mutation Gibdd_fileUpload($input: FileUploadInput!) {
    gibdd_fileUpload(input: $input) {
      code
      message
      result {
        fileId
        size
        contentType
      }
      success
    }
  }
`;

export const GRZ_DECODE = gql`
  query Gibdd_grzDecode($fileId: String!) {
    gibdd_grzDecode(fileID: $fileId) {
      grz
      grzs
      logUuid
    }
  }
`;

export const STS_DECODE = gql`
  query Gibdd_stsDecode($fileId: String!, $companyUuid: String) {
    gibdd_stsDecode(fileID: $fileId, CompanyUUID: $companyUuid) {
      brand
      chassis
      color
      grz
      logUuid
      model
      owner_fio
      stsNumber
      trailer
      view
      vin
      year
    }
  }
`;

export const SEARCH_MARK = gql`
  query Exch_searchMark($markName: String!) {
    exch_searchMark(markName: $markName) {
      id
      name
      shortName
      objectId
      priority
      presignURL {
        fileID
        method
        url
        xAmxTagging
      }
    }
  }
`;

export const SEARCH_MODEL = gql`
  query Exch_searchModel($markId: String!, $modelName: String!) {
    exch_searchModel(markID: $markId, modelName: $modelName) {
      id
      name
      yearFrom
      yearTo
      markId
      presignURL {
        fileID
        method
        url
        xAmxTagging
      }
    }
  }
`;
export const PAYMENT_CREATE = gql`
  mutation Export_paymentCreate($companyUuid: String!, $orderId: String!) {
    export_paymentCreate(companyUUID: $companyUuid, orderID: $orderId) {
      code
      success
      message
      result {
        orderID
        orderUrl
      }
    }
  }
`;
