import React from "react";

import { Apartment } from "@mui/icons-material";
import { Box } from "@mui/material";
import { GetData } from "contexts/DataContext";
import { isObjectEmpty } from "data/functions";

import { Mousewheel, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

export function DepartmentImage(props) {
  const { dataObj } = GetData();

  return (
    <Box
      sx={{
        textAlign: "center",
      }}
    >
      {!isObjectEmpty(props?.images) && (
        <Swiper
          modules={[Pagination, Navigation, Mousewheel]}
          mousewheel={{
            releaseOnEdges: true,
          }}
          pagination={{
            dynamicBullets: true,
          }}
          slidesPerView={
            dataObj?.deviceWidth?.isMobile || dataObj?.deviceWidth?.isPad
              ? 1
              : 3
          }
          spaceBetween={16}
        >
          {(props?.images ? props?.images : []).map((el, index) => (
            <SwiperSlide key={index}>
              <Box
                key={index}
                sx={{
                  margin: "0.4rem 0",

                  height: dataObj?.deviceWidth?.isMobile
                    ? "calc(68vw - 0.66rem)"
                    : dataObj?.deviceWidth?.isPad
                      ? "calc(50vh - 0.66rem)"
                      : "50vh",
                  width: "100%",
                  backgroundImage: "url(" + el + ")",
                  backgroundSize: "cover",
                  backgroundPosition: "center center",
                  backgroundRepeat: "no-repeat",
                }}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
      {isObjectEmpty(props?.images) && (
        <Apartment sx={{ height: "7rem", width: "7rem" }} />
      )}
    </Box>
  );
}
