import { Button, SwipeableDrawer, Typography } from "@mui/material";
import React from "react";

import styled from "@emotion/styled";
import { grey } from "@mui/material/colors";
import { Box } from "@mui/system";
import { isObjectEmpty } from "data/functions";

const Puller = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
  borderRadius: 3,
  height: 6,
  left: "calc(50% - 15px)",
  position: "absolute",
  top: 8,
  width: 30,
}));

export function DrawerBlock(props) {
  return (
    <SwipeableDrawer
      ModalProps={{
        keepMounted: true,
      }}
      PaperProps={{
        sx: {
          height: props?.height ? props?.height : "calc(60% - 56px)",
          // top: 64,
        },
      }}
      anchor="bottom"
      disableSwipeToOpen={true}
      onClose={props?.onCloseFunc}
      onOpen={() => {}}
      open={props?.open}
      sx={{
        zIndex: "1500",
        height: props?.height ? props?.height : `calc(60% - 56px)`,
        position: "relative",
      }}
    >
      <Box
        onClick={props?.onCloseFunc}
        sx={{
          position: "absolute",
          top: 0,
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          visibility: "visible",
          right: 0,
          left: 0,
          display: !props?.open ? "none" : undefined,
        }}
      >
        <Puller />
      </Box>
      {props?.title && (
        <Typography sx={{ marginTop: "12px", padding: "0.4rem" }} variant="h6">
          {props?.title}
        </Typography>
      )}
      <Box sx={{ marginTop: props?.title ? "0px" : "22px", overflow: "auto" }}>
        {props?.showFull && (
          <Button
            onClick={() => props?.onClickItemFunc("")}
            sx={{
              // padding: "4px",
              width: "100%",
              textAlign: "center",
              "&:hover": {
                backgroundColor: "rgb(0 0 0 / 5%)",
              },
            }}
            variant="outlined"
          >
            Все
          </Button>
        )}
        <Box>
          {!isObjectEmpty(props?.itemArray) &&
            props?.itemArray.map((el, ind) => (
              <Box
                key={ind}
                onClick={() => props?.onClickItemFunc(el)}
                sx={{
                  cursor: "pointer",
                  // minHeight: "40px",
                  "&:hover": {
                    backgroundColor: "rgb(0 0 0 / 5%)",
                  },
                }}
              >
                {props?.component &&
                  React.cloneElement(props?.component, {
                    el: el,
                    index: ind,
                  })}
                {/* <PassedComponent {...props?.component} index={ind} el={el} /> */}
              </Box>
            ))}
        </Box>
      </Box>
    </SwipeableDrawer>
  );
}
