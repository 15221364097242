import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { PageTitle } from "components/PageTitle";
import { OidcLoginMutation } from "components/gql/mutations/OidcLogin";
import { GetCInfo } from "contexts/CompanyContext";
import { createTokenData } from "data/functions";
import { jwtDecode } from "jwt-decode";
import React, { useEffect, useState } from "react";

export function AuthAdmin(props) {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(true);
  const handleMouseDownPassword = () => setShowPassword(false);
  const [errorText, setErrorText] = React.useState();
  const [login, setLogin] = React.useState();
  const [password, setPassword] = React.useState();
  const [oidcLoginQuery] = OidcLoginMutation();
  const adminRoles = ["Administrator", "Superuser"];
  const webRoles = ["Webservice"];
  const { cInfo } = GetCInfo();
  useEffect(() => {
    if (errorText)
      setTimeout(() => {
        setErrorText("");
      }, 5000);
  }, [errorText]);

  async function checkAdmin() {
    if (!login || !password) return;
    let { data, error } = await oidcLoginQuery({
      variables: { input: { password: password, username: login } },
    });
    if (data) {
      if (data?.auth_oidcLogIn?.data?.accessToken) {
        let decode = jwtDecode(data?.auth_oidcLogIn?.data?.accessToken);
        if ((decode?.realm_access?.roles ?? []).length > 0) {
          const foundAdmin = decode?.realm_access?.roles.some((r) =>
            adminRoles.includes(r),
          );
          if (foundAdmin) {
            let obj = createTokenData(
              data?.auth_oidcLogIn?.data?.accessToken,
              data?.auth_oidcLogIn?.data?.expiresIn,
              data?.auth_oidcLogIn?.data?.refreshToken,
              data?.auth_oidcLogIn?.data?.refreshExpiresIn,
            );
            props?.setAdminToken(obj);
            setPassword("");
          } else {
            const foundWeb = decode?.realm_access?.roles.some((r) =>
              webRoles.includes(r),
            );
            if (foundWeb && cInfo?.UUID === decode?.company_uuid) {
              let obj = createTokenData(
                data?.auth_oidcLogIn?.data?.accessToken,
                data?.auth_oidcLogIn?.data?.expiresIn,
                data?.auth_oidcLogIn?.data?.refreshToken,
                data?.auth_oidcLogIn?.data?.refreshExpiresIn,
              );
              props?.setAdminToken(obj);
              setPassword("");
            } else {
              setErrorText(
                "У данного пользователя недостаточно прав или несоответствует компания!",
              );
            }
          }
        }
      } else if (
        data?.auth_oidcLogIn?.message &&
        data?.auth_oidcLogIn?.message.includes("Invalid user credentials")
      ) {
        setErrorText("Неправильный логин или пароль");
      }

      // console.log(jwtDecode(data?.auth_oidcLogIn?.data?.accessToken));
    }
    if (error) {
      setErrorText("Не удалось авторизоваться");
    }
  }
  return (
    <React.Fragment>
      <PageTitle home={true} title="Управление приложением" />
      <Box
        sx={{
          height: "80%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",

            padding: "0.4rem",
            width: "80%",
          }}
        >
          <Typography sx={{ textAlign: "center" }} variant="h6">
            Вход
          </Typography>
          <TextField
            fullWidth
            label="Логин"
            name="login"
            onChange={(e) => setLogin(e.target.value)}
            sx={{ margin: "0.4em" }}
            value={login || ""}
            variant="standard"
          />
          <TextField
            InputProps={{
              // <-- This is where the toggle button is added.
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            fullWidth
            label="Пароль"
            name="password"
            onChange={(e) => setPassword(e.target.value)}
            sx={{ margin: "0.4em" }}
            type={showPassword ? "text" : "password"}
            value={password || ""}
            variant="standard"
          />
          <Button
            // fullWidth
            onClick={() => {
              checkAdmin();
            }}
            sx={{ margin: "0.4em" }}
            variant="outlined"
          >
            Войти
          </Button>
          <Typography
            color="error"
            sx={{ padding: "0.4rem", minHeight: "60px", textAlign: "center" }}
            variant="body2"
          >
            {errorText}
          </Typography>
        </Box>
      </Box>
    </React.Fragment>
  );
}
