// let _obj = {};
import {
  CarRepair,
  Construction,
  Description,
  LocalGroceryStore,
  LocalMall,
} from "@mui/icons-material";
import { Box } from "@mui/material";
import { onCompletedOrders } from "components/gql/queris/GetOrdersList";
import { constants } from "data/globals";
import React from "react";
import { useInView } from "react-intersection-observer";
const levenshtein = require("fast-levenshtein");

export function logGQLError(queryName, e) {
  try {
    let text =
      getFirstInArray(e?.networkError?.result?.errors)?.message || e.message;
    sessionStorage.setItem(
      "error_" + new Date(Date.now()).toISOString(),
      queryName + ": " + text,
    );

    consoleBeauty(queryName + ": " + text, "GraphQL", "red");
  } catch (error) {}
}

export function setLocalStorageItem(paramName, paramValue) {
  try {
    localStorage.setItem(paramName, JSON.stringify(paramValue));
  } catch (error) {}
}

export const isAppOnline = async () => {
  if (!window.navigator.onLine) return false;

  const url = new URL(window.location.origin);
  url.searchParams.set("q", new Date().toString());

  try {
    const response = await fetch(url.toString(), {
      cache: "no-store",
      method: "HEAD",
    });

    return response.ok;
  } catch {
    return false;
  }
};
export const isActiveChannel = (channels, name) => {
  const find = (channels ?? []).find((el) => {
    if (el?.name === name) return true;
    else return false;
  });
  return find?.active || false;
};

export function getLocalStorageItem(paramName) {
  try {
    return saveParseJson(localStorage.getItem(paramName));
  } catch (error) {
    return "";
  }
}

export function saveParseJson(val) {
  try {
    return JSON.parse(val);
  } catch (error) {
    return val;
  }
}

export function saveMap(val, name = "") {
  try {
    val.map(() => true);
    return val ?? [];
  } catch (error) {
    console.log([val, name]);
    return [];
  }
}

export function getLocalToken() {
  return saveParseJson(localStorage.getItem("token") || `""`)?.replace('"', "");
}

export function findInArray(array, value, param = "") {
  if (isObjectEmpty(array)) return null;
  return array.find((element) => {
    if (
      (param ? element[param] : element) === value ||
      isIdentical(param ? element[param] : element, value)
    )
      return true;
    return false;
  });
}

export function getParamToken() {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get("token") || "";
}

export function getParamTokenData() {
  const searchParams = new URLSearchParams(window.location.search);
  let token = searchParams.get("token") || "";
  let expires_in = searchParams.get("expires_in") || "";
  let refresh_token = searchParams.get("refresh_token") || "";
  let refresh_expires_in = searchParams.get("refresh_expires_in") || "";
  searchParams.delete("token");
  searchParams.delete("expires_in");
  searchParams.delete("refresh_token");
  searchParams.delete("refresh_expires_in");

  if (token || refresh_token) {
    window.history.replaceState(
      {},
      "",
      searchParams?.size !== 0 ? "?" + searchParams : "/",
    );
    setLocalStorageItem("tokenDataOriginal", {
      access_token: token,
      expires_in: expires_in,
      refresh_expires_in: refresh_expires_in,
      refresh_token: refresh_token,
    });
    return createTokenData(
      token,
      expires_in,
      refresh_token,
      refresh_expires_in,
    );
  } else return {};
}

export function createTokenData(
  token,
  expires_in,
  refresh_token,
  refresh_expires_in,
) {
  return {
    accessToken: token ? token : "",
    accessTokenExpires: expires_in
      ? new Date(Date.now() + 1000 * expires_in)
      : "",
    accessTokenExpiresSoon: expires_in
      ? new Date(Date.now() + 1000 * expires_in * 0.8)
      : "",
    refreshToken: refresh_token ? refresh_token : "",
    refreshTokenExpires: refresh_expires_in
      ? new Date(Date.now() + 1000 * refresh_expires_in)
      : "",
  };
}
export const changeInArray = (obj, val, curState) => {
  if (!obj[curState]) obj[curState] = val;
  else if (!isIdentical(obj[curState], val)) obj[curState] = val;
  return obj;
};

export function getFirstInArray(arr) {
  if (!Array.isArray(arr)) return null;
  return arr[0];
}

export const getUA = () => {
  let device = "Unknown";
  const ua = {
    Android: /Android/i,
    BlackBerry: /BlackBerry/i,
    Bluebird: /EF500/i,
    "Chrome OS": /CrOS/i,
    Datalogic: /DL-AXIS/i,
    "Generic Linux": /Linux/i,
    Honeywell: /CT50/i,
    Windows: /IEMobile|Windows/i,
    Zebra: /TC70|TC55/i,
    iPad: /iPad/i,
    iPhone: /iPhone/i,
    iPod: /iPod/i,
    macOS: /Macintosh/i,
  };
  Object.keys(ua).map((v) => navigator.userAgent.match(ua[v]) && (device = v));
  return device;
};

// export const isObjectEmpty = (objectName) => {
//   if (
//     JSON.stringify(objectName) === "{}" ||
//     objectName === undefined ||
//     objectName === null
//   ) {
//     return true;
//   } else return false;
// };
export const getStrDeforeG = (str) => {
  if (!str) return "";
  let regex = /(\S+)\?/gm;
  let arr = regex.exec(str);
  if (arr && arr[1]) return arr[1];
  return "";
};

export const isPlateCorrect = (str) => {
  if (!str) return false;
  let regex = /^\S{1}\d{3}\S{2}\d{2,3}$/gm;
  let arr = regex.exec(str);
  if (arr) return true;
  return false;
};

export const rounded = function (number) {
  if (number) return +number.toFixed(2);
  else return 0;
};

export function getNearestElement(str, arr, param = "") {
  return [...arr].sort(
    (a, b) =>
      levenshtein.get(
        str.toLowerCase(),
        param ? a[param].toLowerCase() : a.toLowerCase(),
      ) -
      levenshtein.get(
        str.toLowerCase(),
        param ? b[param].toLowerCase() : b.toLowerCase(),
      ),
  )[0];
}

export const isObjectEmpty = (objectName) => {
  if (objectName === null) return true;
  if (objectName === undefined) return true;
  if (objectName === "undefined") return true;
  if (objectName === "00000000-0000-0000-0000-000000000000") return true;
  if (
    objectName instanceof Array &&
    objectName.length === 0 &&
    Object.keys(objectName).length === 0
  )
    return true;
  try {
    if (JSON.stringify(objectName) === "{}") return true;
    if (JSON.stringify(objectName) === '""') return true;
    if (JSON.stringify(objectName) === "") return true;
  } catch (error) {}

  if (typeof objectName === "number" && objectName === 0) return true;
  if (
    Object.prototype.toString.call(objectName) === "[object Date]" &&
    objectName.getFullYear() === 1
  )
    return true;
  else return false;
};

export const getOrderIcon = function (type) {
  if (translateOrderType(type) === constants.ruTypeZN)
    return (
      <Construction
        // color="dimblue"
        sx={{
          margin: "0px 2px 0px 0px",
          width: "1.2em",
          height: "1.2em",
        }}
      />
    );
  if (translateOrderType(type) === constants.ruTypeZR)
    return (
      <CarRepair
        // color="dimblue"
        sx={{
          margin: "0px 2px 0px 0px",
          width: "1.2em",
          height: "1.2em",
        }}
      />
    );
  if (translateOrderType(type) === constants.ruTypeZP)
    return (
      <LocalMall
        // color="dimblue"
        sx={{
          margin: "0px 2px 0px 0px",
          width: "1.2em",
          height: "1.1em",
        }}
      />
    );
  if (translateOrderType(type) === constants.ruTypeR)
    return (
      <LocalGroceryStore
        // color="dimblue"
        sx={{
          margin: "0px 2px 0px 0px",
          width: "1.2em",
          height: "1.1em",
        }}
      />
    );
  return (
    <Description
      // color="dimblue"
      sx={{
        margin: "0px 2px 0px 0px",
        width: "1.2em",
        height: "1.2em",
      }}
    />
  );
};

export const isIdentical = (arr1, arr2) => {
  if (isObjectEmpty(arr1) && isObjectEmpty(arr2)) return true;
  if (Array.isArray(arr1) && isObjectEmpty(arr2))
    if (
      JSON.stringify(arr1) === JSON.stringify([]) ||
      JSON.stringify(arr1) === JSON.stringify({})
    )
      return true;
    else return false;
  if (Array.isArray(arr2) && isObjectEmpty(arr1))
    if (
      JSON.stringify(arr2) === JSON.stringify([]) ||
      JSON.stringify(arr2) === JSON.stringify({})
    )
      return true;
    else return false;
  return JSON.stringify(arr1) === JSON.stringify(arr2);
};

export const createId = () =>
  (
    Math.floor(Math.random() * (99999999999 - 10000000000 + 1)) + 10000000000
  ).toString();

export const assignedArray = (array) => {
  return Object.assign([], array);
};

export const assignedObject = (array) => {
  return saveParseJson(JSON.stringify(array || {}));
};

export const joinParams = (stateValue, newParamValue, defType) => {
  let newValue = undefined;
  if (!stateValue) {
    newValue = defType;
    stateValue = defType;
  }
  if (Object.prototype.toString.call(stateValue) === "[object Array]") {
    let stringifiedObj = JSON.stringify(newParamValue);
    // let id = stateValue?.id || "";
    // if (id) {
    //   let find = stateValue.find((el) => el?.id === id);
    //   if (find) {
    //     if (!isIdentical(find, newParamValue)) {
    //       find = newParamValue;
    //     }
    //     return stateValue;
    //   }
    // }

    if (!stateValue.some((item) => JSON.stringify(item) === stringifiedObj)) {
      newValue = [...stateValue, newParamValue];
    } else {
      newValue = stateValue;
    }
  }
  if (Object.prototype.toString.call(stateValue) === "[object Object]") {
    newValue = {
      ...stateValue,
      ...newParamValue,
    };
  }
  return newValue;
};

export const translateUrgency = (val) => {
  if (val === 2) return "Срочная рекомендация";
  else return "Выполнить по возможности";
};

export const translateOrderStatus = (str, type = "") => {
  switch (str) {
    case "CANCELED":
      return constants.ruCANCELED;
    case "COMPLETED":
      return constants.ruCOMPLETED;
    case "IN_WORK":
      return constants.ruIN_WORK;
    case "READY":
      return constants.ruREADY;
    default:
      return "";
  }
};

export const translateError = (str) => {
  switch (str) {
    case "Permission denied":
      return "Отсутствует разрешение на использование";
    case "rpc error: code = Internal desc = Error has occurred in request: invalid status code":
      return "Функция сейчас недоступна";
    case "rpc error: code = Internal desc = Error has occurred in request: unsupported GRZ":
      return "Не удалось расшифровать гос. номер";
    case "rpc error: code = Internal desc = no balance":
      return "Функция сейчас недоступна";
    case "rpc error: code = Internal desc = requests limit reached":
      return "Превышено количество использований в месяц";
    default:
      return "";
  }
};

export const translateResKey = (str, type = "") => {
  switch (str) {
    case "brand":
      return "Марка";
    case "brandNormalized":
      return "Модель";
    case "brandOriginal":
      return "Марка";
    case "chassis":
      return "Шасси (рама)";
    case "color":
      return "Цвет";
    case "description":
      return "Описание";
    case "engineFuelType":
      return "Тип двигателя";
    case "enginePowerHp":
      return "Мощность двигателя (л.с.)";
    case "enginePowerKw":
      return "Мощность двигателя (кВт)";
    case "grz":
      return "Гос. номер";
    case "marks":
      return "Марка";
    case "message":
      return "Сообщение";
    case "model":
      return "Модель";
    case "models":
      return "Модели";
    case "name":
      return "Название";
    case "sts":
      return "Номер СТС";
    case "stsNumber":
      return "Номер СТС";
    case "trailer":
      return "Кузов";
    case "vin":
      return "VIN";
    case "year":
      return "Год";
    default:
      return "";
  }
};

export const isOrderStatusActive = (str) => {
  switch (str) {
    case "CANCELED":
      return false;
    case "COMPLETED":
      return false;
    case "IN_WORK":
      return true;
    case "READY":
      return true;
    default:
      return false;
  }
};

export const translatePayStatus = (str) => {
  switch (str) {
    case "NONE":
      return "";
    case "PAID":
      return constants.ruPAID;
    case "PAY_WAITING":
      return constants.ruPAY_WAITING;
    default:
      return "";
  }
};

export const translateOrderType = (str) => {
  switch (str) {
    case "ЗАКАЗ_НАРЯД":
      return constants.ruTypeZN;
    case "ЗАКАЗ_ПОКУПАТЕЛЯ":
      return constants.ruTypeZP;
    case "ЗАЯВКА_НА_РЕМОНТ":
      return constants.ruTypeZR;
    case "РЕАЛИЗАЦИЯ_ТОВАРОВ":
      return constants.ruTypeR;
    case "СДЕЛКА":
      return constants.ruTypeBP;
    default:
      return "";
  }
};

export function roundMinutes(date) {
  try {
    return date.getMinutes() >= 45
      ? date.setHours(date.getHours() + 1) && date.setMinutes(0)
      : date.getMinutes() >= 30
        ? date.setMinutes(45)
        : date.getMinutes() >= 15
          ? date.setMinutes(30)
          : date.setMinutes(15);
  } catch {
    return undefined;
  }
}

export const getDateMinusTime = (mounth = 0, days = 0, minutes = 0) => {
  var someDate = new Date(); // add arguments as needed
  if (mounth > 0) someDate.setMonth(someDate.getMonth() - mounth);
  if (days > 0) someDate.setDate(someDate.getDate() - days);
  if (minutes > 0) someDate.setMinutes(someDate.getMinutes() - minutes);

  return someDate;
};

function isDateValid(dateStr) {
  return !isNaN(new Date(dateStr));
}

export function parseRFC3339(dString) {
  if (!isDateValid(dString)) return;
  if (isObjectEmpty(new Date(dString))) return;
  return new Date(dString);
}

export function dateToString(
  date,
  withHours = false,
  onlyHours = false,
  withSecs = false,
) {
  try {
    if (onlyHours) {
      let res =
        ("0" + date.getHours()).slice(-2) +
        ":" +
        ("0" + date.getMinutes()).slice(-2);
      if (withSecs) {
        res += ":" + ("0" + date.getSeconds()).slice(-2);
      }
      return res;
    }
    let res =
      ("0" + date.getDate()).slice(-2) +
      "." +
      ("0" + (date.getMonth() + 1).toString()).slice(-2) +
      "." +
      date.getFullYear();

    if (withHours) {
      res +=
        " " +
        ("0" + date.getHours()).slice(-2) +
        ":" +
        ("0" + date.getMinutes()).slice(-2);
      if (withSecs) {
        res += ":" + ("0" + date.getSeconds()).slice(-2);
      }
    }

    return res;
  } catch {
    return undefined;
  }
}

export const recursiveSearchCat = (arr, id, param) =>
  (Array.isArray(arr?.children) ? arr?.children : []).reduce((found, n) => {
    return (
      found ??
      ((param ? n[param] : n) === id ? n : recursiveSearchCat(n, id, param))
    );
  }, null);

export const recursiveParentSearch = (arr, id, param) => {
  return (Array.isArray(arr?.children) ? arr?.children : []).reduce(
    (found, n) => {
      return (
        found ??
        ((param ? n[param] : n) === id
          ? arr
          : recursiveParentSearch(n, id, param))
      );
    },
    null,
  );
};

export const getBreadcrumbs = (arr, id, param, br = []) => {
  let find = (!isObjectEmpty(arr) ? arr : []).find((el) => el?.id === id);
  if (find) {
    br = [find, ...br];
    if (find?.parent_id !== "")
      return getBreadcrumbs(arr, find?.parent_id, "id", br);
    else return br;
  } else return br;
};

export function searchInCatArray(arr, value, key) {
  if (arr) {
    if (arr[key] === value) return arr;
    let find = (arr?.elements ?? []).find((obj) => obj[key] === value);
    if (!isObjectEmpty(find)) return find;

    for (var i = 0; i < (arr?.children ?? []).length; i++) {
      if (arr?.children[i][key] === value) {
        return arr?.children[i];
      } else {
        find = searchInCatArray(arr?.children[i], value, key);
        if (!isObjectEmpty(find)) return find;
      }
    }
  }

  // console.log(result);
  return undefined;
}

export const fullHex = (hex, alpha = "") => {
  let r = hex.slice(1, 2);
  let g = hex.slice(2, 3);
  let b = hex.slice(3, 4);

  r = parseInt(r + r, 16);
  g = parseInt(g + g, 16);
  b = parseInt(b + b, 16);

  // return {r, g, b}
  if (alpha) {
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else {
    return "rgb(" + r + ", " + g + ", " + b + ")";
  }
};

//convert hex to rgb
export const hex2rgb = (hex, alpha = "") => {
  if (!hex) return undefined;
  if (hex.length === 4) {
    return fullHex(hex, alpha);
  }

  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  if (alpha) {
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else {
    return "rgb(" + r + ", " + g + ", " + b + ")";
  }
};

export function formatPhone(string) {
  let re = /\+?\(?\)?\s?-?/gi;
  let result = string.replace(re, "");
  return result;
}

export function createTextComment(params) {
  // let car = "";
  // if (!isObjectEmpty(params.usedCar?.car?.marka.name))
  //   car = car + params.usedCar?.car?.marka.name + ";";
  // if (!isObjectEmpty(params.usedCar?.car?.model.name))
  //   car = car + params.usedCar?.car?.model.name + ";";
  // if (!isObjectEmpty(params.usedCar?.regNum))
  //   car = car + "№" + params.usedCar?.regNum + ";";
  // if (!isObjectEmpty(params.usedCar?.car?.vin))
  //   car = car + params.usedCar?.car?.vin + ";";
  // car = car.replaceAll(";", " ");
  return (
    "" +
    (params.name ? "ФИО: " + params.name : "") +
    // (car ? "\nАвтомобиль: " + car : "") +
    (params.departmentName
      ? "\nПодразделение: " +
        (params?.region
          ? params?.region + ", " + params.departmentName
          : params.departmentName)
      : "") +
    (params.platform ? "\nУстройство: " + params.platform : "") +
    (params.comment ? "\nКомментарий: " + params.comment : "")
  );
}

export function createDealVariables(params) {
  //need to rewrite according to https://www.npmjs.com/package/yup
  let variables = {
    input: {
      car: {
        id: params.usedCar?.car?.id,
        marka: {
          id: params.usedCar?.car?.marka?.id,
          name: params.usedCar?.car?.marka?.name,
        },
        model: {
          id: params.usedCar?.car?.model?.name,
          name: params.usedCar?.car?.model?.id,
        },
        vin: params.usedCar?.car?.vin,
        year: params.usedCar?.car?.year,
      },
      comment: params.comment,
      companyUUID: params.companyUUID,
      departmentID: params.departmentID,
      fileID: params?.fileIDs,
      marketing: {
        advChannel: "WebAppApplication",
        sourceID: "App_Request",
        utm: [
          {
            type: "utm_source",
            value: "5Swebapp",
          },
          {
            type: "utm_device",
            value: params.platform,
          },
        ],
      },
      presentID: params.giftIDs,
      recommendationID: params.recIDs,
      serviceID: params.servIDs,
      startAt: params.dealDate,
    },
  };
  if (params.promocode) {
    variables = {
      ...variables,
      input: {
        ...variables.input,
        promocode: params.promocode,
      },
    };
  }
  let userData = {
    name: params.name,
    phone: formatPhone(params.usedPhone),
    userID: params.clientID,
  };
  variables = {
    ...variables,
    input: {
      ...variables.input,
      userData: userData,
    },
  };

  return variables;
}

export function getOrderDate(item) {
  if (isObjectEmpty(item)) return "";
  if (
    translateOrderType(item.type) === constants.ruTypeZR &&
    !isObjectEmpty(item?.planDate)
  ) {
    return " на " + dateToString(parseRFC3339(item?.planDate));
  } else if (!isObjectEmpty(item?.docDate)) {
    return " от " + dateToString(parseRFC3339(item?.docDate));
  }
  return "";
}

export function getCarImage(obj, id) {
  if (!id) return "";
  id = Number(id);
  try {
    if (isObjectEmpty(obj[id])) return "";
    if (!isObjectEmpty(obj[id]?.imageURL800)) return obj[id]?.imageURL800;
    if (!isObjectEmpty(obj[id]?.imageURL400)) return obj[id]?.imageURL400;
    if (!isObjectEmpty(obj[id]?.imageURL200)) return obj[id]?.imageURL200;
    if (!isObjectEmpty(obj[id]?.imageURL100)) return obj[id]?.imageURL100;
    if (!isObjectEmpty(obj[id]?.imageURL50)) return obj[id]?.imageURL50;
  } catch (e) {
    return "";
  }
}

export function getImageUrlByName(str) {
  if (isObjectEmpty(str)) return "";
  let url = "";
  str = str.toLowerCase();
  if (str.includes("масл") && str.includes("фильтр"))
    return "https://cloud5.5-systems.ru/5systems/images/maslfiltr.png";
  if (str.includes("воздушн") && str.includes("фильтр"))
    return "https://cloud5.5-systems.ru/5systems/images/vozdlfiltr.png";
  if (str.includes("фильтр") && str.includes("салон"))
    return "https://cloud5.5-systems.ru/5systems/images/salonfilters.png";
  if (str.includes("тормозн") && str.includes("жидкост"))
    return "https://cloud5.5-systems.ru/5systems/images/tormzhidk.jpg";
  if (str.includes("охлаждающ") && str.includes("жидкост"))
    return "https://cloud5.5-systems.ru/5systems/images/antifriz.jpg";
  if ((str.includes("жидкост") || str.includes("масла")) && str.includes("гур"))
    return "https://cloud5.5-systems.ru/5systems/images/gur.png";
  if (
    str.includes("акпп") ||
    str.includes("коробки передач") ||
    str.includes("коробка передач")
  )
    return "https://cloud5.5-systems.ru/5systems/images/akpp.png";
  if (str.includes("макпп") || str.includes("мкпп"))
    return "https://cloud5.5-systems.ru/5systems/images/kpp.png";
  if (str.includes("передн") && str.includes("редукт"))
    return "https://cloud5.5-systems.ru/5systems/images/preductor.png";
  if (
    (str.includes("редуктор") || str.includes("мост")) &&
    str.includes("задн")
  )
    return "https://cloud5.5-systems.ru/5systems/images/reduktor_zadnego_mosta.png";
  if (str.includes("топливн") && str.includes("фильтр"))
    return "https://cloud5.5-systems.ru/5systems/images/toplivnfiltr.png";
  if (str.includes("масла кпп") || str.includes("масло кпп"))
    return "https://cloud5.5-systems.ru/5systems/images/kpp.png";
  if ((str.includes("ремень") || str.includes("ремня")) && str.includes("грм"))
    return "https://cloud5.5-systems.ru/5systems/images/grm.png";
  if (str.includes("свечей зажигания") || str.includes("свечи зажигания"))
    return "https://cloud5.5-systems.ru/5systems/images/svechi.png";
  if (
    str.includes("масл") &&
    (str.includes("двигател") || str.includes("замена"))
  )
    return "https://cloud5.5-systems.ru/5systems/images/maslo.png";
  if (str.includes("защита картера"))
    return "https://cloud5.5-systems.ru/5systems/images/karter.png";
  if (str.includes("шиномонтаж") || str.includes("переобувка"))
    return "https://cloud5.5-systems.ru/5systems/images/kolesa.png";
  if (
    str.includes("хранение") &&
    (str.includes("колес") || str.includes("шин"))
  )
    return "https://cloud5.5-systems.ru/5systems/images/khranenie-shin.png";
  if (
    str.includes("становка") &&
    (str.includes("колес") || str.includes("шин"))
  )
    return "https://cloud5.5-systems.ru/5systems/images/shini.png";
  if (str.includes("техническое обслуживание"))
    return "https://cloud5.5-systems.ru/5systems/images/techobsl.png";
  if (str.includes("прокладка поддона"))
    return "https://cloud5.5-systems.ru/5systems/images/poddon.png";
  if (str.includes("сцеплени"))
    return "https://cloud5.5-systems.ru/5systems/images/sceplenie.png";
  if (str.includes("гидро") && str.includes("блок"))
    return "https://cloud5.5-systems.ru/5systems/images/gidroblock.png";
  if (
    str.includes("кондиционер") ||
    str.includes("кондициониров") ||
    (str.includes("климатическ") && str.includes("установк"))
  )
    return "https://cloud5.5-systems.ru/5systems/images/kondicioner.png";
  if (
    (str.includes("замен") && str.includes("колодок")) ||
    (str.includes("тормозн") &&
      (str.includes("колодк") ||
        str.includes("колодок") ||
        str.includes("диск") ||
        str.includes("механиз") ||
        str.includes("систем")))
  )
    return "https://cloud5.5-systems.ru/5systems/images/tormoza.png";
  if (str.includes("стабилизатор"))
    return "https://cloud5.5-systems.ru/5systems/images/stabilizator.png";
  if (str.includes("рулев") && (str.includes("тяга") || str.includes("тяги")))
    return "https://cloud5.5-systems.ru/5systems/images/tiagarulevaai.png";
  if (str.includes("текущий ремонт автомобиля"))
    return "https://cloud5.5-systems.ru/5systems/images/remont-i-obsluzhivan.jpg";
  if (str.includes("диагностич") && str.includes("работ"))
    return "https://cloud5.5-systems.ru/5systems/images/diagnrabot.png";
  if (
    str.includes("подвеск") ||
    (str.includes("ходов") && str.includes("част")) ||
    str.includes("вибростенд")
  )
    return "https://cloud5.5-systems.ru/5systems/images/podveska.png";
  if (str.includes("мойк") && (str.includes("колес") || str.includes("шин")))
    return "https://cloud5.5-systems.ru/5systems/images/moikashin.jpg";
  if (
    str.includes("развал-схождение") ||
    str.includes("схождение") ||
    str.includes("сход-развал")
  )
    return "https://cloud5.5-systems.ru/5systems/images/razval.jpg";

  if (str.includes("топливн") && str.includes("систем"))
    return "https://cloud5.5-systems.ru/5systems/images/common_rail6.png";
  if (
    str.includes("балансировк") &&
    (str.includes("колес") || str.includes("шин"))
  )
    return "https://cloud5.5-systems.ru/5systems/images/stend_shinomontazhnyy_i_balansirovochnyy.png";
  if (
    str.includes("автосигнализа") ||
    str.includes("сигнализации") ||
    str.includes("сигнализация")
  )
    return "https://cloud5.5-systems.ru/5systems/images/avtosignalizacziya.png";
  if (str.includes("тонирование"))
    return "https://cloud5.5-systems.ru/5systems/images/tonirovanie.png";
  if (str.includes("бронирование"))
    return "https://cloud5.5-systems.ru/5systems/images/bronirovanie_kyzova.png";
  if (str.includes("предпусковой"))
    return "https://cloud5.5-systems.ru/5systems/images/predpuskovoi.png";
  if (str.includes("электрооборудовани"))
    return "https://cloud5.5-systems.ru/5systems/images/electroob.png";
  if (str.includes("цилиндр"))
    return "https://cloud5.5-systems.ru/5systems/images/cylinders.png";

  if (str.includes("магнитол"))
    return "https://cloud5.5-systems.ru/5systems/images/magnitola.png";

  if (
    str.includes("двигател") &&
    (str.includes("стекл") || str.includes("очистител"))
  )
    return "https://cloud5.5-systems.ru/5systems/images/electrodvigatel.png";
  if (str.includes("двигател") || str.includes("диагностика двс"))
    return "https://cloud5.5-systems.ru/5systems/images/dvigatel.png";
  if (str.includes("рулевое управление"))
    return "https://cloud5.5-systems.ru/5systems/images/rulevoeypr.png";
  if (
    str.includes("угл") &&
    str.includes("установк") &&
    (str.includes("колес") || str.includes("шин"))
  )
    return "https://cloud5.5-systems.ru/5systems/images/ugliustanovkikoles.png";
  if (str.includes("трансмисси"))
    return "https://cloud5.5-systems.ru/5systems/images/transmissiya.png";

  if (str.includes("шлицев") && str.includes("соедин"))
    return "https://cloud5.5-systems.ru/5systems/images/shliceviesoed.png";

  if (str.includes("редуктор"))
    return "https://cloud5.5-systems.ru/5systems/images/reduktor.png";
  if (str.includes("ступиц"))
    return "https://cloud5.5-systems.ru/5systems/images/stupic.png";
  if (str.includes("замен") && str.includes("шруса"))
    return "https://cloud5.5-systems.ru/5systems/images/shrus.png";
  if (str.includes("амортизатор"))
    return "https://cloud5.5-systems.ru/5systems/images/amortizator.png";
  if (str.includes("техосмотр"))
    return "https://cloud5.5-systems.ru/5systems/images/techosmotr.png";
  if (str.includes("компьютерн") && str.includes("диагност"))
    return "https://cloud5.5-systems.ru/5systems/images/diagnostika3.png";
  if (str.includes("антикоррозийная"))
    return "https://cloud5.5-systems.ru/5systems/images/antikorobr.png";
  if (str.includes("слесарн"))
    return "https://cloud5.5-systems.ru/5systems/images/slesarnieraboty.png";
  if (str.includes("диагностика"))
    return "https://cloud5.5-systems.ru/5systems/images/diagnostika.png";

  if (str.includes("барабан") && str.includes("тормоз"))
    return "https://cloud5.5-systems.ru/5systems/images/diskovyie-i-barabannyie-tormoza.png";
  if (str.includes("форсун"))
    return "https://cloud5.5-systems.ru/5systems/images/forsunki.png";
  // value.name.toLowerCase().includes("шиномонтаж")
  if (str.includes("дроссельн"))
    return "https://cloud5.5-systems.ru/5systems/images/droseln.png";
  if (str.includes("вариатор"))
    return "https://cloud5.5-systems.ru/5systems/images/variator.png";
  if (str.includes("кузов"))
    return "https://cloud5.5-systems.ru/5systems/images/kuzovnoi.png";
  if (
    (str.includes("горячий") ||
      str.includes("холодный") ||
      str.includes("твердый")) &&
    str.includes("воск")
  )
    return "https://cloud5.5-systems.ru/5systems/images/wash.jpg";
  if (
    str.includes("чернение") &&
    (str.includes("резины") || str.includes("колес") || str.includes("шин"))
  )
    return "https://cloud5.5-systems.ru/5systems/images/chernenierezini.jpg";

  if (
    ((str.includes("уборка") || str.includes("чистка")) &&
      (str.includes("салона") ||
        str.includes("пластика") ||
        str.includes("багажника"))) ||
    str.includes("химчистка")
  )
    return "https://cloud5.5-systems.ru/5systems/images/uborka-salona.jpg";
  // value.name.toLowerCase().includes("шиномонтаж")
  if (
    (str.includes("уборка") || str.includes("чистка")) &&
    (str.includes("cтекол") || str.includes("стекла"))
  )
    return "https://cloud5.5-systems.ru/5systems/images/uborka-stekol.jpg";
  // value.name.toLowerCase().includes("шиномонтаж")
  if (str.includes("переднего крыла") || str.includes("переднее крыло"))
    return "https://cloud5.5-systems.ru/5systems/images/front-wing.png";

  if (str.includes("мойка") || str.includes("полировка"))
    return "https://cloud5.5-systems.ru/5systems/images/moika.jpg";
  if (str.includes("автосервис") || str.includes("авторабота"))
    return "https://cloud5.5-systems.ru/5systems/images/carservice.png";
  if (str.includes("якор") || str.includes("стартер"))
    return "https://cloud5.5-systems.ru/5systems/images/starter.png";

  return url;
}

export function getCarName(
  obj,
  array = [],
  any = "Неизвестный автомобиль",
  withoutMark = false,
) {
  if (isObjectEmpty(obj)) return "";

  if (Object.prototype.toString.call(obj) === "[object String]" && array) {
    array.forEach((el) => {
      if (el?.car?.id === obj) {
        obj = el;
      }
    });
  }

  if (Object.prototype.toString.call(obj) === "[object Object]") {
    let marka = obj?.car?.marka?.name || "";
    let model = deleteBrackets(obj?.car?.model?.name);
    if (withoutMark) {
      if (model) return model;
    } else {
      if (marka || model) {
        if (marka && model) return marka + " " + model;
        else return marka + model;
      }
    }

    let vin = obj?.car?.vin;
    if (vin) return vin;

    let plate = obj?.regNum;
    if (plate) return plate;
  }

  return any;
}

export function getCarMark(carID, carArray, markArray) {
  if (
    isObjectEmpty(carID) ||
    isObjectEmpty(carArray) ||
    isObjectEmpty(markArray)
  )
    return "";

  let car = {};
  carArray.forEach((el) => {
    if (el?.car?.id === carID) {
      car = el;
    }
  });
  if (isObjectEmpty(car)) return "";

  let markaID = car?.car?.marka?.id;
  if (isObjectEmpty(markaID)) return "";

  let base64Img = markArray?.[markaID];
  if (base64Img) return base64Img;
  else return "";
}

export function deleteBrackets(input) {
  let regex = /[^0-9a-zA-Z]+/gm;
  return input.replace(regex, " ");
}

export async function updateRecs(recQuery, carID, { dataObj, pushDataObj }) {
  let { data, error } = await recQuery({
    variables: {
      carId: carID,
      userId: dataObj?.clientID,
    },
  });

  if (data) {
    let recsArray = data?.export_recommendationsList;
    // let obj = {};
    let storageObj = dataObj?.recsArray;
    if (!storageObj || !isIdentical(recsArray, dataObj?.recsArray[carID])) {
      // obj = { ...dataObj.recsArray, ...{ [carID]: recsArray } };
      pushDataObj({ [carID]: recsArray }, "recsArray", {});
    }
  } else if (error) {
    pushDataObj({ [carID]: [] }, "recsArray", {});
  }
}

export async function updateOrders(ordersQuery, { dataObj, setDataObj }) {
  let { data, error } = await ordersQuery();

  if (data) {
    // console.log("Инициировано обновление списка заказов");
    onCompletedOrders(data, { dataObj, setDataObj });
  } else if (error) {
    onCompletedOrders(undefined, { dataObj, setDataObj });
  }
}

export async function getFlowFunc(flowRefetch, ch, company, ph = "") {
  if (ch !== "telegram" && ch !== "sms" && ch !== "phone") return;
  let variables = {
    channel: ch.toUpperCase(),
    companyUuid: company,
  };

  if (!isObjectEmpty(ph)) {
    variables = {
      ...variables,
      phone: ph,
    };
  }
  let { data } = await flowRefetch({
    variables: variables,
  });
  if (data && data?.auth_getExtFlowInit) {
    let flowID = data?.auth_getExtFlowInit?.flowID || "";
    let expiresIn = data?.auth_getExtFlowInit?.expiresIn || "";
    let telegramURL = data?.auth_getExtFlowInit?.telegramURL || "";
    let codeLength = data?.auth_getExtFlowInit?.codeLength || 0;
    return [flowID, expiresIn, telegramURL, codeLength];
  }
  return ["", "", "", 0];
}

// export async function getElements(
//   elRefetch,
//   elPriceRefetch,
//   id,
//   { dataObj, setDataObj },
//   { cInfo },
// ) {
//   let { data } = await elRefetch({
//     variables: { categoryId: id, companyUuid: cInfo?.UUID },
//   });
//   if (data) {
//     let elements = onCompletedCategoryElements(
//       data,
//       { dataObj, setDataObj },
//       id,
//     );
//     if (elements)
//       getElementsPrices(
//         elPriceRefetch,
//         elements,
//         { dataObj, setDataObj },
//         { cInfo },
//       );
//   }
// }

export async function getCategories(
  svcRefetch,
  item,
  { dataObj, pushDataObj },
  companyUUID,
) {
  if (!companyUUID) return;
  let { data } = await svcRefetch({
    variables: { categoryId: item?.id || "", companyUuid: companyUUID },
  });
  if (data) {
    if (!isObjectEmpty(data?.export_servicesList?.categories)) {
      data?.export_servicesList?.categories.forEach((el) => {
        pushDataObj(
          {
            ...el,
            parent_name: item?.id ? item?.name : "Услуги",
          },
          "folderList",
          [],
        );
      });
    }
    if (!isObjectEmpty(data?.export_servicesList?.services)) {
      data?.export_servicesList?.services.forEach((el) => {
        pushDataObj(
          {
            ...el,
            parent_name: item?.id ? item?.name : "Услуги",
          },
          "serviceList",
          [],
        );
      });
    }
  }
}

export async function getElementsPrices(
  elPriceRefetch,
  servicesArr,
  { dataObj, setDataObj },
  { cInfo },
  { sectionItemAdd },
  waitAnswer = false,
) {
  let elements = [];
  servicesArr.map((el) => (elements = [...elements, el.id]));
  let carID = dataObj?.usedCar?.car?.id || "all";
  let variables = {
    companyUuid: cInfo?.UUID,
    serviceId: elements,
  };
  if (dataObj?.usedCar?.car?.marka?.id) {
    variables = {
      ...variables,
      markaID: dataObj?.usedCar?.car?.marka?.id,
    };
  }
  if (dataObj?.usedCar?.car?.model?.id) {
    variables = {
      ...variables,
      modelId: dataObj?.usedCar?.car?.model?.id,
    };
  }

  let { data, error } = await elPriceRefetch({
    variables: variables,
  });
  let res = {};
  if (data) {
    if (!isObjectEmpty(data?.export_servicePrices)) {
      data?.export_servicePrices?.forEach((item) => {
        let it = {
          price: item?.price,
          priceType: item?.priceType,
          salePrice: item?.salePrice,
          serviceID: item?.service?.id,
        };
        sectionItemAdd("prices", it, carID);
        if (waitAnswer && servicesArr.length === 1) res = it;
      });
    }
  }
  if (error) {
    servicesArr.forEach((el) => {
      let it = {
        price: 0,
        priceType: "",
        salePrice: 0,
        serviceID: el?.id,
      };
      sectionItemAdd("prices", it, carID);
      if (waitAnswer && servicesArr.length === 1) res = it;
    });
  }
  if (waitAnswer && servicesArr.length === 1) return res;
}

export function UseOnScreen({ children }) {
  const { inView, ref } = useInView({
    /* Optional options */
    threshold: 0,
  });
  return (
    <React.Fragment>
      <Box ref={ref} sx={{ opacity: 0, zIndex: 2 }}>
        {" "}
      </Box>
      <Box
        sx={{
          zIndex: 2,
          position: inView ? undefined : "sticky",
          bottom: inView ? undefined : 0,
          width: "100%",
        }}
      >
        {children}
      </Box>
    </React.Fragment>
  );
}

export function consoleBeauty(
  text,
  badgeText = "",
  bgColor = "#95B46A",
  color = "white",
) {
  console.log(
    `%c ${badgeText} `,
    `color: ${color}; background-color: ${bgColor}`,
    text,
  );
}

export async function imageToBase64(image) {
  const reader = new FileReader();
  reader.readAsDataURL(image);
  const data = await new Promise((resolve, reject) => {
    reader.onload = () =>
      resolve(reader.result.replace("data:", "").replace(/^.+,/, ""));

    reader.onerror = (error) => reject(error);
  });
  return data;
}

export async function decodePlate(grz, decodeQuery) {
  let { data, error } = await decodeQuery({
    variables: {
      grz: grz,
    },
  });
  if (data) {
    return [data?.gibdd_carReport, null];
  }
  if (error) {
    return [{}, error];
  }
  return [{}, null];
}

export async function decodeVin(vin, decodeQuery) {
  let { data, error } = await decodeQuery({ variables: { vin: vin } });
  if (data) {
    let mods = data?.exch_decodeVin?.data;
    let obj = {
      marks: [],
      models: [],
    };

    if (!isObjectEmpty(mods?.manufacturers)) {
      mods?.manufacturers?.forEach((marka) => {
        obj = {
          ...obj,
          marks: [...obj.marks, marka],
        };
      });
    }

    if (!isObjectEmpty(mods?.models)) {
      mods?.models?.forEach((model) => {
        obj = {
          ...obj,
          models: [...obj.models, model],
        };
      });
    }
    return [obj.marks, obj.models, null];
  }
  if (error) {
    return [[], [], error];
  }
  return [[], [], null];
}

export async function getCompanyStyle(
  uuid,
  getCompanyExtDataQuery,
  { cInfo, setCInfo },
) {
  if (!uuid) return;
  let { data } = await getCompanyExtDataQuery({
    variables: {
      companyUuid: uuid,
    },
  });
  if (data && !isObjectEmpty(data?.acc_getCompanyExtData)) {
    let extData = data?.acc_getCompanyExtData;
    let style = extData?.styleUrls;
    let loyalty = extData?.loyalty;
    let metrika = extData?.metrika;

    if (!isIdentical(extData?.title, cInfo?.title)) {
      setCInfo(extData?.title, "title");
    }
    if (!isIdentical(style, cInfo?.style)) {
      setCInfo(style, "style");
    }
    if (!isIdentical(loyalty, cInfo?.loyalty)) {
      setCInfo(loyalty, "loyalty");
    }
    if (!isIdentical(metrika, cInfo?.metrika)) {
      setCInfo(metrika, "metrika");
    }

    return;
  }
}

export async function getPinnedServices(
  uuid,
  getServicesQuery,
  { cInfo, setCInfo },
) {
  if (!uuid) return;
  let { data } = await getServicesQuery({
    variables: { companyUuid: cInfo?.UUID, recommended: true },
  });
  if (data && data?.export_servicesList?.services) {
    let services = data?.export_servicesList?.services;
    if (!isIdentical(services, cInfo?.pinnedServices)) {
      setCInfo(services, "pinnedServices");
    }
    return;
  }
}

export async function getService(uuid, service_id, getServicesQuery) {
  if (!uuid || !service_id) return {};
  let { data } = await getServicesQuery({
    variables: { companyUuid: uuid, serviceId: service_id },
  });
  if (data && data?.export_servicesList?.services) {
    let services = data?.export_servicesList?.services;
    return (services ?? []).length > 0 ? services[0] : {};
  }
}
