import React, { useEffect, useState } from "react";

import { Telegram } from "@mui/icons-material/";
import { Box, Button, Link, Typography } from "@mui/material";
import { Timer } from "components/Timer";
import { ExtLoginMutation } from "components/gql/mutations/ExtLogin";
import { ExtFlowInitQuery } from "components/gql/queris/GetExtFlowInit";
import { GetCInfo } from "contexts/CompanyContext";
import { getFlowFunc, getStrDeforeG, isObjectEmpty } from "data/functions";

export const TelegramBlock = (props) => {
  const { cInfo } = GetCInfo();
  const [remainingTime, setRemainingTime] = useState();
  const [timerCount, setTimerCount] = useState();
  const [tgUrl, setTgUrl] = useState("");
  const [tgUrlShort, setTgUrlShort] = useState("");
  const [flowID, setFlowID] = useState("");
  const [loginRefetch] = ExtLoginMutation();
  const [flowRefetch] = ExtFlowInitQuery();

  useEffect(() => {
    if (
      remainingTime > 0 &&
      remainingTime % 3 === 0 &&
      props?.method === "telegram" &&
      flowID
    ) {
      loginRefetch({
        variables: {
          input: {
            company_uuid: cInfo?.UUID,
            flow_id: flowID,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remainingTime]);

  useEffect(() => {
    getFlow("telegram");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function setTimer(secs) {
    setTimerCount(new Date(Date.now() + secs * 1000));
  }

  async function getFlow(ch, ph = "") {
    let [flowID, expiresIn, telegramURL] = await getFlowFunc(
      flowRefetch,
      ch,
      cInfo?.UUID,
      ph,
    );

    if (flowID) {
      setTgUrl(telegramURL);
      setTgUrlShort(getStrDeforeG(telegramURL));
      setTimer(expiresIn);
      setFlowID(flowID);
      return;
    } else {
      props?.setMethod("");
      return;
    }
  }

  return (
    <React.Fragment>
      {props?.method === "telegram" &&
        !isObjectEmpty(tgUrl) &&
        !props?.flowVars?.loading && (
          <React.Fragment>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                margin: "1rem 0px",
              }}
            >
              <Typography align="center" variant="h6">
                Для подтверждения перейдите по ссылке:
              </Typography>
              <Link
                href={tgUrl}
                sx={{ display: "flex" }}
                target="_blank"
                variant="h6"
              >
                <Telegram /> {tgUrlShort ? tgUrlShort : tgUrl}
              </Link>
              <Box sx={{ paddingTop: "1rem" }}>
                <Timer
                  expiryTimestamp={timerCount}
                  method={props?.method}
                  remainingTime={remainingTime}
                  setMethod={props?.setMethod}
                  setRemainingTime={setRemainingTime}
                  variant="url"
                />
              </Box>
              {remainingTime === 0 && (
                <Button
                  onClick={() => {
                    getFlow("telegram");
                  }}
                  variant="outlined"
                >
                  Обновить ссылку
                </Button>
              )}
            </Box>
          </React.Fragment>
        )}
    </React.Fragment>
  );
};
