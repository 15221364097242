import { DeleteSweep, ShoppingBasket } from "@mui/icons-material/";
import { Badge, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { CarSelectBlock } from "components/CarSelectBlock";
import { PageTitle } from "components/PageTitle";
import { GetBasket } from "contexts/BasketContext";
import { GetData } from "contexts/DataContext";
import { RejectDialog } from "pages/recs/RejectDialog";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function RecsTitle(props) {
  const { dataObj } = GetData();
  const { basket, sections } = GetBasket();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const checked = basket[sections?.recommendations]?.length > 0 || false;
  return (
    <React.Fragment>
      <Grid container spacing={0} sx={{ alignItems: "center" }}>
        <Grid item xs={checked ? 8 : 10}>
          <PageTitle alignStart={true} title="Рекомендации" />
        </Grid>
        {checked && (
          <Grid item xs={2}>
            <IconButton
              disableRipple
              onClick={() => setOpen(true)}
              size="small"
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                borderRadius: "200px",

                //
              }}
            >
              <Tooltip title={"Отказаться от рекомендаций"}>
                <DeleteSweep
                  sx={{
                    fill: "#ab0000",
                    // animation:
                    //   (dataObj.currentBasketCount || 0) > 0
                    //     ? "wobble 3s infinite"
                    //     : "none",
                    borderRadius: "20px",
                  }}
                />
              </Tooltip>
              {!dataObj?.deviceWidth?.isMobile && (
                <Typography
                  color="#ab0000"
                  sx={{ fontSize: "12px" }}
                  variant="body1"
                >
                  {"Отказаться"}
                </Typography>
              )}
            </IconButton>
          </Grid>
        )}
        <Grid item xs={2}>
          <IconButton
            disableRipple
            onClick={() => {
              navigate("/basket");
            }}
            size="small"
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              borderRadius: "200px",

              //
            }}
          >
            <Tooltip title={"Перейти в корзину"}>
              <Badge badgeContent={dataObj.currentBasketCount} color="primary">
                <ShoppingBasket
                  sx={{
                    animation:
                      (dataObj.currentBasketCount || 0) > 0
                        ? "wobble 3s infinite"
                        : "none",
                    borderRadius: "20px",
                  }}
                />
              </Badge>
            </Tooltip>
            {!dataObj?.deviceWidth?.isMobile && (
              <Typography sx={{ fontSize: "12px" }} variant="body1">
                {"Корзина"}
              </Typography>
            )}
          </IconButton>
        </Grid>
      </Grid>
      <CarSelectBlock />
      <RejectDialog handleClose={() => setOpen(false)} open={open} />
    </React.Fragment>
  );
}
