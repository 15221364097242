import { useQuery } from "@apollo/client";
import { GET_DEPARTMENT_LIST } from "components/gql/gql_queries";
import { GetCInfo } from "contexts/CompanyContext";
import {
  consoleBeauty,
  isIdentical,
  isObjectEmpty,
  logGQLError,
} from "data/functions";

export function DepartmentsQuery() {
  const queryName = "CompanyDepartments";
  const { cInfo, setCInfo } = GetCInfo();
  const query = useQuery(GET_DEPARTMENT_LIST, {
    skip: !cInfo.UUID,
    variables: {
      companyUuid: cInfo?.UUID,
    },
    onCompleted: (data) => {
      if (isObjectEmpty(data?.export_companyDepartments?.departments)) {
        setCInfo([], "departments");
      } else if (
        !isIdentical(
          data?.export_companyDepartments?.departments,
          cInfo?.departments,
        )
      ) {
        consoleBeauty("Получен список подразделений", "GraphQL", "darkgreen");
        setCInfo(data.export_companyDepartments?.departments, "departments");
      }
      if (isObjectEmpty(data?.export_companyDepartments?.regions)) {
        setCInfo([], "regions");
      } else if (
        !isIdentical(data?.export_companyDepartments?.regions, cInfo?.regions)
      ) {
        setCInfo(data.export_companyDepartments?.regions, "regions");
      }
    },
    onError: (e) => {
      setCInfo([], "departments");
      logGQLError(queryName, e);
    },
  });
  return query;
}
