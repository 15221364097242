import { useTheme } from "@emotion/react";
import { Box, Checkbox } from "@mui/material";
import { GetPrices } from "components/gql/queris/GetPrices";
import { GetBasket } from "contexts/BasketContext";
import { GetCInfo } from "contexts/CompanyContext";
import { GetData } from "contexts/DataContext";
import {
  getElementsPrices,
  getImageUrlByName,
  isObjectEmpty,
} from "data/functions";
import { ServiceBlock } from "pages/services/list/ServiceBlock";
import React from "react";
import { getServiceArrayByMode } from "./FindBlock";

export default function ServiceList(props) {
  const { dataObj, setDataObj } = GetData();
  const theme = useTheme();
  const { cInfo } = GetCInfo();
  const { getPrice, inBasket, sectionItemAdd, sectionItemDelete, sections } =
    GetBasket();
  const [pricesQuery] = GetPrices();

  const serviceArr = getServiceArrayByMode(
    dataObj?.serviceList,
    props?.searchMode,
    props?.parent?.id,
  );

  React.useEffect(() => {
    let arr = getServiceArrayByMode(
      dataObj?.serviceList,
      props?.searchMode,
      props?.parent?.id,
    );
    props?.setNoServices(isObjectEmpty(arr));
    if (!isObjectEmpty(arr) && cInfo?.UUID) {
      getElementsPrices(
        pricesQuery,
        arr,
        {
          dataObj,
          setDataObj,
        },
        { cInfo },
        { sectionItemAdd },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.parent, dataObj?.usedCar, dataObj?.serviceList]);

  function handleChecked(value) {
    let find = inBasket(value, sections.services);
    if (!find) sectionItemAdd("basket", value, sections.services);
    else sectionItemDelete("basket", value, sections.services);
  }

  // React.useEffect(() => {
  //   props?.setNoServices(isObjectEmpty(serviceArr));
  // }, [serviceArr]);

  return (
    <React.Fragment>
      <Box
        sx={{
          // padding: "0.4rem",
          marginTop: "-16px",
          display: "inline-flex",
          flexDirection: "row",
          flexWrap: "wrap",
          width: dataObj?.deviceWidth?.isMobile ? "100%" : "100%",
          alignItems: dataObj?.deviceWidth?.isMobile ? undefined : "stretch",
        }}
      >
        {serviceArr.map((value, index) => {
          let carID = dataObj?.usedCar?.car?.id;
          let priceObj = getPrice(value?.id, carID);
          let price = priceObj?.price || 0;
          let salePrice = priceObj?.salePrice || 0;
          let subPrice = priceObj?.priceType === "min_price" ? "от " : "";
          const labelId = `checkbox-list-label-${value}`;
          let check = inBasket(value, sections.services);

          const CheckboxComponent = (
            <Checkbox
              checked={check || false}
              disableRipple
              edge="start"
              inputProps={{ "aria-labelledby": labelId }}
              size="small"
              sx={{
                padding: "0.4rem",
                margin: 0,
                zIndex: 2,
                "& .MuiSvgIcon-root": {
                  color: theme?.custom?.telegramColors?.button_color,
                },
              }}
              tabIndex={-1}
            />
          );

          const PriceComponent = price && (
            <React.Fragment>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "nowrap",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {subPrice + salePrice + " руб. "}
                <strike>{price > salePrice ? price + " руб." : ""}</strike>
              </Box>
            </React.Fragment>
          );

          return (
            <Box
              key={index}
              sx={{
                // flexGrow: "1",
                width: dataObj?.deviceWidth?.isMobile
                  ? "49%"
                  : dataObj?.deviceWidth?.isPad
                    ? "24%"
                    : "19%",
              }}
            >
              <ServiceBlock
                check={check}
                description={value?.description}
                iconElement={CheckboxComponent}
                image={getImageUrlByName(value.name)}
                item={value}
                price={PriceComponent || ""}
                setItem={handleChecked}
                thereIsEl={true}
                title={value.name}
              />
            </Box>
          );
        })}
      </Box>
    </React.Fragment>
  );
}
