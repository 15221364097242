import { GetData } from "contexts/DataContext";

import styled from "@emotion/styled";
import { Face3, Face6 } from "@mui/icons-material/";
import {
  Avatar,
  Box,
  Card as MuiCard,
  CardContent,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing } from "@mui/system";
import React, { useState } from "react";

import { StorageDialog } from "components/StorageDialog";

import { isObjectEmpty } from "data/functions";
import ProfileMenu from "pages/profile/info/ProfileMenu";

const Card = styled(MuiCard)(spacing);
const Typography = styled(MuiTypography)(spacing);
const Centered = styled.div`
  text-align: center;
`;

export function UserMainData(props) {
  const { dataObj } = GetData();
  const [name, setName] = useState(dataObj?.clientName);
  const [openDialog, setOpenDialog] = React.useState(false);

  React.useEffect(() => {
    if (!isObjectEmpty(dataObj?.clientName)) setName(dataObj?.clientName);
    // eslint-disable-next-line
  }, [dataObj?.clientName]);

  return (
    <Card
      sx={{
        position: "relative",
        margin: "0.5rem",
        background: "transparent",
        flexGrow: 1,
      }}
    >
      <CardContent>
        <Centered>
          <ProfileMenu setOpen={props?.setOpen} />
          {/* <Avatar alt={name ? name : "Не задано"}> */}

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              padding: "8px",
            }}
          >
            <Avatar
              alt={dataObj?.clientName}
              src={dataObj?.clientAvatar}
              sx={{ height: "120px", width: "120px" }}
            >
              {dataObj?.clientName ? dataObj?.clientName.charAt(0) : ""}
            </Avatar>
          </Box>

          {/* </Avatar> */}
          <Typography component="div" variant="h6">
            {dataObj?.clientInfoArray?.name?.firstName ||
            dataObj?.clientInfoArray?.name?.lastName ||
            dataObj?.clientInfoArray?.name?.middleName ? (
              <Box>
                <Box fontWeight="fontWeightMedium">
                  {dataObj?.clientInfoArray?.name?.lastName +
                    " " +
                    dataObj?.clientInfoArray?.name?.firstName}
                </Box>
                <Box fontWeight="fontWeightMedium">
                  {" "}
                  {dataObj?.clientInfoArray?.name?.middleName}
                </Box>
              </Box>
            ) : name ? (
              name
            ) : (
              "Не задано"
            )}

            {dataObj.clientInfoArray?.jobTitle && (
              <Box fontWeight="fontWeightRegular">Должность</Box>
            )}
          </Typography>
        </Centered>
      </CardContent>
      <StorageDialog setOpen={setOpenDialog} status={openDialog} />
    </Card>
  );
}
