import React from "react";

import { LoadingButton } from "@mui/lab";
import { NewDealMutation } from "components/gql/mutations/AddNewDeal";
import { OrdersListQuery } from "components/gql/queris/GetOrdersList";
import { GetAlertData } from "contexts/AlertContext";
import { GetBasket } from "contexts/BasketContext";
import { GetCInfo } from "contexts/CompanyContext";
import { GetData } from "contexts/DataContext";
import { createDealVariables, createTextComment } from "data/functions";
import { updateOrders } from "data/functions";
import { useNavigate } from "react-router-dom";

export function DealButton(props) {
  const { contextDelete, isEmptyContext, registration, sectionItemDelete } =
    GetBasket();
  const [addNewDeal, newDealVars] = NewDealMutation();
  const { dataObj, setDataObj } = GetData();
  const navigate = useNavigate();
  const [orderListGQL] = OrdersListQuery();
  const { cInfo } = GetCInfo();
  const { setAlert } = GetAlertData();

  const handleSubmit = async () => {
    if (
      props?.dealAddData?.date &&
      props?.dealAddData?.usedPhone &&
      !isEmptyContext("registration")
    ) {
      let dealDate = new Date(props?.dealAddData?.date).toISOString();
      if (new Date(props?.dealAddData?.date) < new Date(Date.now())) {
        setAlert("Выбранная дата\nнекорректна", "warning");
        return;
      }
      let recIDs = [];
      (registration?.recommendations || []).forEach((element) => {
        recIDs = [...recIDs, element?.id];
      });
      let servIDs = [];
      (registration?.services || []).forEach((element) => {
        servIDs = [...servIDs, element?.id];
      });
      let giftIDs = [];
      (registration?.gifts || []).forEach((element) => {
        giftIDs = [...giftIDs, element?.id];
      });
      let fileIDs = [];
      (props?.dealAddData?.filesArray || []).forEach((element) => {
        fileIDs = [...fileIDs, element?.fileId];
      });
      let params = {
        clientID: dataObj?.clientID,
        comment: props?.dealAddData?.comment,
        companyUUID: cInfo?.UUID,
        dealDate: dealDate,
        departmentID: props?.dealAddData?.department?.ID,
        departmentName: props?.dealAddData?.department?.name,
        fileIDs: fileIDs,
        giftIDs: giftIDs,
        // logined: true,
        name: props?.dealAddData?.name,
        platform: dataObj?.platform,
        promocode: props?.dealAddData?.promocode,
        recIDs: recIDs,
        region: props?.dealAddData?.region,
        servIDs: servIDs,
        usedCar: dataObj?.usedCar,
        usedPhone: props?.dealAddData?.usedPhone,
      };
      let commentJoined = createTextComment(params);
      params = {
        ...params,
        comment: commentJoined,
      };
      let variables = createDealVariables(params);
      // console.log(variables);
      let { data } = await addNewDeal({
        variables: variables,
      });
      if (data && data?.export_dealAdd?.code === 200) {
        updateOrders(orderListGQL, { dataObj, setDataObj });
        await deleteFromBasket(recIDs, "recommendations");
        await deleteFromBasket(servIDs, "services");
        await deleteFromBasket(giftIDs, "gifts");
        contextDelete("registration");
        navigate("/");
      }
    } else {
      setAlert(
        "Для отправки\n заявки заполните\n Номер телефона и Услуги",
        "warning",
      );
    }
  };

  function deleteFromBasket(array = [], section) {
    (array ?? []).forEach((element) => {
      sectionItemDelete("basket", { id: element }, section);
    });
  }

  return (
    <LoadingButton
      fullWidth
      loading={newDealVars?.loading}
      onClick={handleSubmit}
      sx={{ margin: "0.4rem 0px 0.4rem 0px" }}
      variant="outlined"
      // loadingPosition="start"
    >
      Отправить заявку
    </LoadingButton>
  );
}
