import { Apartment } from "@mui/icons-material";
import { InputAdornment, MenuItem, TextField } from "@mui/material";
import { GetCInfo } from "contexts/CompanyContext";
import { GetData } from "contexts/DataContext";
import { isObjectEmpty } from "data/functions";
import React from "react";

export default function DepField(props) {
  const { dataObj } = GetData();
  const { cInfo } = GetCInfo();

  return (
    <TextField
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Apartment />
          </InputAdornment>
        ),
        style: {
          fontSize: dataObj?.deviceWidth?.isMobile ? "0.875rem" : "1rem",
        },
      }}
      error={props?.error}
      fullWidth
      helperText={props?.error ? "Выберите подразделение" : ""}
      label="Подразделение"
      mb={2}
      mt={2}
      name="department"
      // defaultValue=""
      select
      sx={{
        minWidth: dataObj?.deviceWidth?.isMobile ? "inherit" : "30%",
        margin: "0.3rem 0",
      }}
      type="text"
      value={props?.department?.name || ""}
      variant="standard"
    >
      {!isObjectEmpty(props?.departments) &&
        props?.departments?.map((item, index) => {
          if (
            isObjectEmpty(cInfo?.regions) ||
            (!isObjectEmpty(cInfo?.regions) && cInfo?.regions.length <= 1)
          ) {
            return (
              <MenuItem
                key={index}
                onClick={(e) => props?.setDepartment(item)}
                value={item.name}
              >
                {item.name}
              </MenuItem>
            );
          } else {
            if (item?.region === props?.region) {
              return (
                <MenuItem
                  key={index}
                  onClick={(e) => props?.setDepartment(item)}
                  value={item?.name}
                >
                  {item?.name}
                </MenuItem>
              );
            } else return null;
          }
        })}
      {isObjectEmpty(props?.departments) && (
        <MenuItem>Подразделений не найдено</MenuItem>
      )}
    </TextField>
  );
}
