import { useQuery } from "@apollo/client";
import { GET_COMPANY_CHANNELS } from "components/gql/gql_queries";
import { GetCInfo } from "contexts/CompanyContext";

import {
  consoleBeauty,
  isIdentical,
  isObjectEmpty,
  logGQLError,
} from "data/functions";

export function ChannelsQuery() {
  const queryName = "Channels";
  const { cInfo, setCInfo } = GetCInfo();
  const query = useQuery(GET_COMPANY_CHANNELS, {
    skip: !cInfo.UUID,
    variables: {
      companyUuid: cInfo?.UUID,
    },
    onCompleted: (data) => {
      if (isObjectEmpty(data.acc_getCompanyChannels)) {
        setCInfo([], "channels");
      } else if (!isIdentical(data.acc_getCompanyChannels, cInfo?.channels)) {
        consoleBeauty("Получен список каналов", "GraphQL", "darkgreen");
        setCInfo(data.acc_getCompanyChannels, "channels");
      }
    },
    onError: (e) => {
      setCInfo([], "channels");
      logGQLError(queryName, e);
    },
  });
  return query;
}
