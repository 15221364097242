import { Button, Menu, MenuItem } from "@mui/material";
import { GetData } from "contexts/DataContext";
import React from "react";

import { FilterList, FilterListOff } from "@mui/icons-material/";

import { Box } from "@mui/system";
import CarListItem from "components/CarListItem";
import { DrawerBlock } from "components/DrawerBlock";
import { getCarName, isObjectEmpty } from "data/functions";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";

export const BoxEl = (props) => {
  if (isObjectEmpty(props?.el)) return "";
  return (
    <React.Fragment>
      {props?.el && (
        <Box
          index={props?.index}
          sx={{ textAlign: "center", padding: "0.4rem" }}
        >
          {props?.el}
        </Box>
      )}
    </React.Fragment>
  );
};
export function FilterBlock(props) {
  const { dataObj } = GetData();
  const [filterArray, setFilterArray] = React.useState([]);
  const [menuVal, setMenuVal] = React.useState("");
  const [openFilter, setOpenFilter] = React.useState(false);
  return (
    <React.Fragment>
      <PopupState popupId="demo-popup-menu" variant="popover">
        {(popupState) => (
          <React.Fragment>
            <Button
              color="inherit"
              id="composition-button"
              sx={{ textTransform: "none" }}
              {...bindTrigger(popupState)}
            >
              <FilterList sx={{ margin: "2px" }} />
              Показать
            </Button>

            <Menu {...bindMenu(popupState)}>
              <MenuItem
                onClick={() => {
                  setOpenFilter(true);
                  setMenuVal("status");
                  setFilterArray(dataObj?.orderStatuses?.sort());
                }}
              >
                По статусу {props.filter?.status ? props.filter?.status : ""}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setOpenFilter(true);
                  setMenuVal("car");
                  setFilterArray(dataObj?.carArrWithSold);
                }}
              >
                По автомобилю{" "}
                {props.filter?.car ? getCarName(props.filter?.car) : ""}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setOpenFilter(true);
                  setMenuVal("type");
                  setFilterArray(dataObj?.orderTypes?.sort());
                }}
              >
                По типу документа {props.filter?.type ? props.filter?.type : ""}
              </MenuItem>
            </Menu>
          </React.Fragment>
        )}
      </PopupState>
      <DrawerBlock
        component={
          menuVal === "car" ? (
            <CarListItem />
          ) : menuVal === "status" ? (
            <BoxEl />
          ) : menuVal === "type" ? (
            <BoxEl />
          ) : undefined
        }
        itemArray={filterArray || []}
        onClickItemFunc={(el = "") => {
          setOpenFilter(false);
          props?.setFilter((e) => {
            return { ...e, ...{ [menuVal]: el } };
          });
        }}
        onCloseFunc={() => {
          setFilterArray([]);
          setOpenFilter(false);
        }}
        open={openFilter}
        showFull={true}
      />
      <Button
        color={
          !isObjectEmpty(props.filter?.car) ||
          !isObjectEmpty(props.filter?.type) ||
          !isObjectEmpty(props.filter?.status)
            ? "secondary"
            : "inherit"
        }
        id="composition-button"
        onClick={() => props?.setFilter({})}
        sx={{ textTransform: "none", right: "0", position: "absolute" }}
      >
        <FilterListOff sx={{ margin: "2px" }} />
      </Button>
    </React.Fragment>
  );
}
