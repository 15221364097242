import React from "react";

import {
  CameraAlt,
  CropFreeOutlined,
  Photo,
  QrCode,
  QrCodeScanner,
  StarOutline,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Scanner } from "@yudiel/react-qr-scanner";
import { NotFoundBlock } from "components/NotFoundBlock";
import { PageTitle } from "components/PageTitle";
import QRBlock, { QRDialog } from "components/QRBlock";
import { GetAlertData } from "contexts/AlertContext";
import { GetData } from "contexts/DataContext";
import { dateToString, parseRFC3339, saveMap } from "data/functions";
import { isObjectEmpty } from "data/functions";
import AccrueInfo from "pages/credits/AccrueInfo";
import HistoryInfo from "pages/credits/HistoryInfo";

export default function Credits() {
  const { dataObj } = GetData();
  const { setAlert } = GetAlertData();
  const [accrueArr, setAccrueArr] = React.useState([]);
  const [historyArr, setHistoryArr] = React.useState([]);
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <PageTitle title="Бонусы" />
        <Box sx={{ overflow: "auto" }}>
          <BonusTotal />
          <AccrueInfo setParentArray={setAccrueArr} />
          <BonusQrBlock setOpen={setOpen} />
          {/* <BonusProgram /> */}
          <HistoryInfo setParentArray={setHistoryArr} />
          <br />
          <SendBonuses credits={dataObj?.creditBalance} />
          <GetBonuses />

          {!isObjectEmpty(dataObj?.presentAccrue) && <BonusServs />}
          {isObjectEmpty(historyArr) &&
            isObjectEmpty(dataObj?.presentAccrue) &&
            isObjectEmpty(accrueArr) && <NotFoundBlock />}
        </Box>
      </Box>
      <QRDialog open={open} setOpen={setOpen} />
    </React.Fragment>
  );
}

function BonusTotal() {
  const { dataObj } = GetData();
  return (
    <Box
      sx={{
        padding: "8px 16px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Typography variant="h5">Мои БОНУСЫ:</Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography sx={{ lineHeight: "0.8" }} variant="h5">
          {dataObj?.creditBalance || 0}
        </Typography>
        <Typography variant="caption">бонусов</Typography>
      </Box>
    </Box>
  );
}

function BonusServs(props) {
  const { dataObj } = GetData();
  return (
    <React.Fragment>
      <Typography sx={{ padding: "16px 16px 4px 16px" }} variant="h5">
        Бонусные услуги
      </Typography>
      <Typography></Typography>
      {(dataObj?.presentAccrue ?? []).map((el, ind) => (
        <Box
          key={ind}
          sx={{
            padding: "4px 24px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant={dataObj?.deviceWidth?.isMobile ? "body2" : "body1"}
          >
            {el.name}
          </Typography>
          <Typography
            variant={dataObj?.deviceWidth?.isMobile ? "body2" : "body1"}
          >
            {el?.lifePeriod?.expireDate !== "0001-01-01 00:00:00 +0000 UTC"
              ? dateToString(parseRFC3339(el?.lifePeriod?.expireDate), true)
              : ""}
          </Typography>
        </Box>
      ))}
    </React.Fragment>
  );
}

function BonusQrBlock(props) {
  return (
    <Box
      onClick={(e) => props?.setOpen(true)}
      sx={{
        maxHeight: "80px",
        margin: "0.4rem",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        borderRadius: "4px",
        position: "relative",
        padding: "8px",
        height: "80px",
        overflow: "hidden",
        cursor: "pointer",
        boxShadow:
          "rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px",
      }}
    >
      <Typography sx={{ textAlign: "left", fontWeight: "400" }} variant="body2">
        Покажите QR-код, чтобы подтвердить списание бонусов или получить заказ
      </Typography>
      <Box
        sx={{
          display: "flex",
          minWidth: "64px",
          width: "64px",
          height: "100%",
          overflow: "hidden",
        }}
      >
        <QRBlock />
      </Box>
    </Box>
  );
}

function BonusProgram() {
  return (
    <Box
      sx={{
        padding: "36px 16px 0px 16px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography variant="h5">Статус: Стандартный</Typography>
    </Box>
  );
}

function GetBonuses(props) {
  const { dataObj } = GetData();
  const [result, setResult] = React.useState([]);
  const [open, setOpen] = React.useState(false);

  const DialogGet = () => {
    const [paused, setPaused] = React.useState(true);
    return (
      open && (
        <Dialog
          onClose={() => {
            setOpen(false);
            setResult([]);
          }}
          open={open}
        >
          <DialogTitle>
            Отсканируйте QR-код друга для получения бонусов
          </DialogTitle>
          <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                padding: "6px",
              }}
            >
              {paused ? (
                <Box
                  onClick={() => setPaused(false)}
                  sx={{
                    width: "250px",
                    height: "250px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                    border: "1px dashed rgba(143, 143, 143, 0.245)",
                  }}
                >
                  <CameraAlt />
                </Box>
              ) : (
                <Scanner
                  components={
                    {
                      // finder: false,
                    }
                  }
                  onError={(e) => {
                    setResult([
                      {
                        rawValue: e?.message,
                        type: "error",
                      },
                    ]);
                    setPaused(true);
                  }}
                  onScan={(e) => {
                    setResult(e);
                    setPaused(true);
                  }}
                  paused={paused}
                  styles={{
                    container: {
                      // overflow: "hidden",
                      maxWidth: "250px",
                      height: "250px",
                    },
                    video: {
                      maxWidth: "250px",
                      height: "250px",
                      overflow: "hidden",
                    },
                    // svg: {
                    //   width: "20px",
                    // },
                  }}
                />
              )}
            </Box>
            <Box sx={{ width: "100%", overflow: "auto" }}>
              {saveMap(result, GetBonuses?.name).map((el, ind) => (
                <Typography
                  color={el?.type ?? undefined}
                  key={ind}
                  sx={{ maxWidth: "100%", textAlign: "center" }}
                  variant="body2"
                >
                  {el?.rawValue}
                </Typography>
              ))}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              fullWidth
              onClick={() => {
                setOpen(false);
                setResult([]);
              }}
              variant="contained"
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      )
    );
  };

  return (
    <React.Fragment>
      {dataObj?.includeBeta && (
        <Box sx={{ padding: "0.4rem" }}>
          <Button
            fullWidth
            onClick={() => {
              setOpen(true);
              // setAlert("Функционал не реализован.", "info");
            }}
            variant="outlined"
          >
            Получить бонусы
          </Button>
          <DialogGet />
        </Box>
      )}
    </React.Fragment>
  );
}

function SendBonuses(props) {
  const { dataObj } = GetData();
  const { setAlert } = GetAlertData();
  const [open, setOpen] = React.useState(false);

  const DialogSend = () => {
    const [credits, setCredits] = React.useState(props?.credits || 0);
    return (
      open && (
        <Dialog
          onClose={() => {
            setOpen(false);
          }}
          open={open}
        >
          <DialogTitle>Передать бонусы</DialogTitle>
          <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                padding: "6px",
              }}
            >
              <TextField
                InputProps={{
                  // disableUnderline: true,
                  inputProps: {
                    style: {
                      // padding: "0",
                    },
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <StarOutline />
                    </InputAdornment>
                  ),
                }}
                // autoFocus
                fullWidth
                label="Количество бонусов"
                // value={text}
                mb={2}
                mt={2}
                name="credits"
                // onChange={(e) => setCredits(e?.target?.value)}
                onChange={(e) => {
                  if (props?.credits >= e.target.value)
                    setCredits(e.target.value.toUpperCase());
                  else setCredits(props?.credits);
                }}
                size="small"
                sx={
                  {
                    // margin: "0",
                  }
                }
                type="Number"
                value={Number(credits) || 0}
                variant={"standard"}
              />
            </Box>
          </DialogContent>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Button
              fullWidth
              onClick={() => {
                // setOpen(false);
                setAlert("Функционал в разработке", "info");
              }}
              sx={{ marginBottom: "4px" }}
              variant="contained"
            >
              Создать QR-код
            </Button>
            <Button
              fullWidth
              onClick={() => {
                setOpen(false);
              }}
              variant="contained"
            >
              Закрыть
            </Button>
          </Box>
        </Dialog>
      )
    );
  };

  return (
    <React.Fragment>
      {dataObj?.includeBeta && (
        <Box sx={{ padding: "0.4rem" }}>
          <Button
            fullWidth
            onClick={() => {
              setOpen(true);
              // setAlert("Функционал не реализован.", "info");
            }}
            variant="outlined"
          >
            Передать бонусы
          </Button>
          <DialogSend />
        </Box>
      )}
    </React.Fragment>
  );
}
