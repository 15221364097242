import { Button } from "@mui/material";
import { GetAlertData } from "contexts/AlertContext";
import { GetData } from "contexts/DataContext";
import { isObjectEmpty, translateOrderType } from "data/functions";
import { constants } from "data/globals";
import { showRoute } from "data/mobapp_functions";
import React from "react";

export function OpenMapButton({ department, docItem }) {
  const { dataObj } = GetData();
  const { setAlert } = GetAlertData();
  return (
    <React.Fragment>
      {!isObjectEmpty(department) &&
        translateOrderType(docItem?.type) === constants.ruTypeZR && (
          <Button
            fullWidth
            onClick={() => {
              if (
                (dataObj?.platform === "Android" ||
                  dataObj?.platform === "iPhone" ||
                  dataObj?.platform === "iPad" ||
                  dataObj?.platform === "iPod") &&
                department?.locationLatitude &&
                department?.locationLongitude
              )
                showRoute(
                  department?.locationLatitude,
                  department?.locationLongitude,
                );
              else {
                let URL = "https://yandex.ru/maps/";
                if (dataObj?.locationParam) {
                  URL =
                    URL +
                    "?rtext=" +
                    dataObj?.locationParam?.location_longitude +
                    "," +
                    dataObj?.locationParam?.location_latitude +
                    "~" +
                    department?.locationLongitude +
                    "," +
                    department?.locationLatitude +
                    "&rtt=auto";
                } else {
                  URL =
                    URL +
                    "?whatshere[point]=" +
                    department?.locationLongitude +
                    "," +
                    department?.locationLatitude +
                    "&whatshere[zoom]=17";
                }
                window.open(URL, "_blank");
              }
            }}
            sx={{ margin: "0.4rem 0px 0.4rem 0px" }}
            variant="outlined"
            // loadingPosition="start"
          >
            Маршрут проезда
          </Button>
        )}
      {dataObj.includeBeta &&
        translateOrderType(docItem?.type) === constants.ruTypeZR && (
          <Button
            fullWidth
            onClick={() =>
              setAlert(
                "Не удалось изменить! \nОбратитесь в автосервис",
                "warning",
              )
            }
            sx={{ margin: "0.4rem 0px 0.4rem 0px" }}
            variant="outlined"
            // loadingPosition="start"
          >
            Перенести запись
          </Button>
        )}

      {dataObj.includeBeta &&
        translateOrderType(docItem?.type) !== constants.ruTypeZN && (
          <Button
            color="error"
            fullWidth
            onClick={() =>
              setAlert(
                "Не удалось отменить! \nОбратитесь в автосервис",
                "warning",
              )
            }
            sx={{
              margin: "0.4rem 0px 0.4rem 0px",
              textDecoration: "underline",
            }}
            // variant="outlined"
            // loadingPosition="start"
          >
            Отменить
          </Button>
        )}
    </React.Fragment>
  );
}
