import { Box, LinearProgress, Paper } from "@mui/material";
import CustomizedBreadcrumbs from "components/Breadcrumbs";
import { NotFoundBlock } from "components/NotFoundBlock";
import { GetServices } from "components/gql/queris/GetServices";
import { GetCInfo } from "contexts/CompanyContext";
import { GetData } from "contexts/DataContext";
import { getBreadcrumbs, getCategories, isObjectEmpty } from "data/functions";
import FolderList from "pages/services/list/FolderList";
import ServiceList from "pages/services/list/ServiceList";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

export const FolderPage = (props) => {
  const { dataObj, pushDataObj, setDataObj } = GetData();
  const [searchParams, setSearchParams] = useSearchParams();
  const [item, setItem] = useState(
    window.history.state
      ? (dataObj?.serviceList ?? [])?.find(
          (el) => el?.id === window.history.state?.folder,
        ) || {}
      : {},
  );
  const [br, setBr] = useState([]);
  const [noFolders, setNoFolders] = useState(false);
  const [noServices, setNoServices] = useState(false);
  const { cInfo } = GetCInfo();
  let searchMode = !isObjectEmpty(searchParams.get("mode")) || false;
  const [svcRefetch, svcVariables] = GetServices();

  useEffect(() => {
    window.history.replaceState(
      { folder: item?.id || "" },
      "",
      window.location.pathname + window.location?.search,
    );

    if (searchParams.get("mode") && item?.id !== "search") {
      searchParams.delete("mode");
      setSearchParams(searchParams);
      deleteSearchItems();
    }
    if (cInfo?.UUID) {
      // setSearchParams({ mode: "list" });
      getCategories(svcRefetch, item, { dataObj, pushDataObj }, cInfo?.UUID);
      let b = getBreadcrumbs(dataObj?.folderList, item?.id || "", "id");
      setBr(b);
    }

    // eslint-disable-next-line
  }, [item, cInfo?.UUID]);

  useEffect(() => {
    if (searchParams.get("mode") === "search") {
      setItem({ id: "search" });
    } else if (item?.id === "search") {
      deleteSearchItems();
      setItem({});
    }
    // eslint-disable-next-line
  }, [searchParams]);

  function deleteSearchItems() {
    setDataObj(
      (dataObj?.folderList ?? []).filter(
        (el) => el.mode !== "search" && el?.id !== "search",
      ),
      "folderList",
    );
    setDataObj(
      (dataObj?.serviceList ?? []).filter((el) => el.mode !== "search"),
      "serviceList",
    );
  }

  return (
    <React.Fragment>
      <Box sx={{ padding: "0 0.4rem 0.4rem 0.4rem" }}>
        <Paper sx={{ position: "sticky", top: 0, zIndex: 3 }}>
          <CustomizedBreadcrumbs array={br} func={setItem} />
        </Paper>

        <Box sx={{ height: "2px" }}>
          {svcVariables.loading && noFolders && noServices && (
            <LinearProgress sx={{ height: "2px" }} />
          )}
        </Box>

        {((isObjectEmpty(svcVariables?.data?.export_servicesList?.services) &&
          isObjectEmpty(svcVariables?.data?.export_servicesList?.categories) &&
          noFolders &&
          noServices) ||
          svcVariables.error) &&
          !svcVariables.loading && <NotFoundBlock />}

        <FolderList
          parent={item}
          searchMode={searchMode}
          setNoFolders={setNoFolders}
          setParent={setItem}
        />
        <ServiceList
          checked={props.checked}
          handleChecked={props.handleChecked}
          parent={item}
          searchMode={searchMode}
          setNoServices={setNoServices}
        />
      </Box>
    </React.Fragment>
  );
};
