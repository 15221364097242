import { Button, Checkbox, Paper } from "@mui/material";

import { GetBasket } from "contexts/BasketContext";
import { isIdentical, isObjectEmpty } from "data/functions";

import React from "react";

export function BasketActions(props) {
  const { basket, contextDelete, getAllBasket, sectionItemDelete } =
    GetBasket();

  function deleteFromBasket() {
    //need to rewrite
    Object.keys(props?.selected).forEach((key) => {
      props?.selected[key].forEach((element) => {
        sectionItemDelete("basket", element, key);
      });
    });
  }

  function checkAll() {
    let res = getAllBasket();
    if (isIdenticalLocal(res, props?.selected)) props?.setSelected({});
    else props?.setSelected(res);
  }

  React.useEffect(() => {
    props?.setSelected(getAllBasket());
    contextDelete("registration");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basket]);

  React.useEffect(() => {
    let val = getAllBasket();
    if (isIdenticalLocal(val, props?.selected) && !isObjectEmpty(val)) {
      props?.setAllSelected(true);
    } else {
      props?.setAllSelected(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.selected]);

  function isIdenticalLocal(main, second) {
    return (
      isIdenticalObjElements(main?.recommendations, second?.recommendations) &&
      isIdenticalObjElements(main?.services, second?.services) &&
      isIdenticalObjElements(main?.gifts, second?.gifts)
    );
  }

  function isIdenticalObjElements(main, second) {
    if (isIdentical(main, second)) return true;
    let error = false;
    for (let i = 0; i < (main ?? []).length; i++) {
      let find = (second ?? []).find((el) => el?.id === (main ?? [])[i]?.id);
      if (isObjectEmpty(find)) {
        error = true;
        break;
      }
    }
    return !error;
  }

  return (
    <Paper
      sx={{
        padding: "6px",
        // marginTop: "12px",
        borderRadius: 0,
        position: "sticky",
        top: 0,
        zIndex: 3,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <Button
        onClick={() => {
          checkAll();
        }}
        sx={{
          textTransform: "none",
          lineHeight: 1,
          padding: "0 4px",
          fontSize: "0.8125rem",
        }}
        variant="text"
      >
        <Checkbox
          checked={props?.allSelected}
          disableRipple
          // edge="start"
          sx={{ padding: "0 4px" }}
        />
        Выбрать все
      </Button>
      {(!isObjectEmpty(props?.selected?.recommendations) ||
        !isObjectEmpty(props?.selected?.services) ||
        !isObjectEmpty(props?.selected?.gifts)) && (
        <Button
          color="error"
          onClick={() => {
            props?.setSelected({});
            deleteFromBasket();
          }}
          size="small"
          sx={{
            textTransform: "none",
            padding: "0 4px",
            fontSize: "0.8125rem",
          }}
          // variant="standart"
          variant="text"
        >
          Удалить выделенные
        </Button>
      )}
    </Paper>
  );
}
