import { Box, Button, Dialog, DialogContent, Typography } from "@mui/material";
import { Timer } from "components/Timer";
import { GetCInfo } from "contexts/CompanyContext";
import { GetAuthData } from "contexts/TAuthContext";
import React from "react";
import QRCode from "react-qr-code";

export default function QRBlock(props) {
  const { token } = GetAuthData();

  return (
    <React.Fragment>
      <Box
        sx={{
          padding: "0.4rem",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <QRCode
          style={{
            maxWidth: "256px",
            width: "100%",
            height: "auto",
            background: "white",
            padding: "0.4rem",
          }}
          value={props?.temp ? "5systems.ru" : token ?? "5systems.ru"}
        />
      </Box>
    </React.Fragment>
  );
}

export function QRDialog(props) {
  const { cInfo } = GetCInfo();
  const { tokenData } = GetAuthData();
  const [active, setActive] = React.useState(
    new Date(tokenData?.accessTokenExpires) > new Date(Date.now()) || undefined,
  );
  // eslint-disable-next-line no-unused-vars
  const [time, setTime] = React.useState();
  const [expiryTimestamp, setExpiryTimestamp] = React.useState();

  React.useEffect(() => {
    setExpiryTimestamp(new Date(tokenData?.accessTokenExpiresSoon));
    setActive(
      new Date(tokenData?.accessTokenExpires) > new Date(Date.now()) ||
        undefined,
    );
  }, [tokenData]);

  return (
    <React.Fragment>
      {props?.open && (
        <Dialog
          maxWidth="sm"
          onClose={(e) => props?.setOpen(false)}
          open={props?.open}
        >
          <DialogContent>
            <Box sx={{ margin: "16px 24px" }}>
              <QRBlock />
            </Box>
            <Typography sx={{ textAlign: "center" }} variant="h6">
              Покажите QR-код, чтобы{" "}
              {cInfo?.loyalty?.used && "подтвердить списание бонусов или "}
              получить заказ
            </Typography>
            {active !== undefined && active && (
              <Timer
                expiryTimestamp={expiryTimestamp}
                setRemainingTime={setTime}
                variant="qr"
              />
            )}
            {active !== undefined && !active && (
              <Typography
                sx={{ textAlign: "center", paddingTop: "12px", color: "red" }}
                variant="body1"
              >
                Не действителен
              </Typography>
            )}
          </DialogContent>

          <Button
            onClick={(e) => props?.setOpen(false)}
            sx={{ margin: "0.4rem" }}
            variant="outlined"
          >
            ОК
          </Button>
        </Dialog>
      )}
    </React.Fragment>
  );
}
