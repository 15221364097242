import { Box } from "@mui/material";

import ParamBlock from "components/ParamBlock";
import { isObjectEmpty, translateError, translateResKey } from "data/functions";
import React from "react";

export function ResponseParamsBlock(props) {
  return (
    <React.Fragment>
      {Object.keys(props?.data).map((key, ind) => {
        if (
          !isObjectEmpty(props?.data[key]) &&
          !isObjectEmpty(translateResKey(key))
        ) {
          return (
            <Box key={props?.name + ind}>
              <ParamBlock
                text={
                  Array.isArray(props?.data[key])
                    ? props?.data[key].join("; ")
                    : translateError(props?.data[key]?.toString()) !== ""
                      ? translateError(props?.data[key]?.toString())
                      : key === "grz" || key === "message"
                        ? props?.data[key].toLowerCase()
                        : props?.data[key]
                }
                title={translateResKey(key)}
              />
            </Box>
          );
        } else return "";
      })}
    </React.Fragment>
  );
}
