import { Box } from "@mui/material";
import { GetData } from "contexts/DataContext";
import { isObjectEmpty } from "data/functions";
import { BoxFolder } from "data/styleGlobals";
import { FolderBlock } from "pages/services/list/FolderBlock";
import React from "react";
import { getServiceArrayByMode } from "./FindBlock";

export default function FolderList(props) {
  const { dataObj } = GetData();
  let folderArr = getServiceArrayByMode(
    dataObj?.folderList,
    props?.searchMode,
    props?.parent?.id,
  );

  React.useEffect(() => {
    props?.setNoFolders(isObjectEmpty(folderArr));
  }, [folderArr, props?.parent]);

  return (
    <React.Fragment>
      <Box
        sx={{
          // padding: "1.0rem 0.4rem 0.4rem 0.4rem",
          // gap: "2%",
          marginTop: "16px",
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          // justifyContent: "space-evenly",
          width: dataObj?.deviceWidth?.isMobile ? "100%" : undefined,
        }}
      >
        {folderArr.map((element, ind) => {
          return (
            <Box
              key={ind}
              onClick={() => props?.setParent(element)}
              sx={{
                padding: "6px",
                width: dataObj?.deviceWidth?.isMobile
                  ? "49%"
                  : dataObj?.deviceWidth?.isPad
                    ? "24%"
                    : "19%",
              }}
            >
              <FolderBlock folder={true} title={element.name} />
            </Box>
          );
        })}
      </Box>
    </React.Fragment>
  );
}
