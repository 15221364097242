import React, { useEffect } from "react";

import { ExtRefreshTokenMutation } from "components/gql/mutations/ExtRefreshToken";
import { SelfDataQuery } from "components/gql/queris/GetSelfData";

import { GetAuthData } from "contexts/TAuthContext";
import {
  createTokenData,
  getLocalStorageItem,
  getParamToken,
  getParamTokenData,
  setLocalStorageItem,
} from "data/functions";
import { isObjectEmpty } from "data/functions";

export function GQLTokenProvider({ children }) {
  const [selfDataQuery] = SelfDataQuery();
  const { setLogined, setTokenData } = GetAuthData();
  const [refreshMutation] = ExtRefreshTokenMutation();

  React.useEffect(() => {
    checkLocalStorage();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      let dataT = getLocalStorageItem("tokenData");
      let logParam = getLocalStorageItem("isLogined");
      if (logParam && !isObjectEmpty(dataT)) {
        checkTokenDataTime(dataT);
      }
    }, 30000);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function checkTokenDataTime(data) {
    let [active, activeSoon, activeRefresh] = decodeTokenTime(data);

    if (active || (active && !activeSoon)) {
      if (!activeSoon) {
        return refreshToken(data?.refreshToken, true);
      }
    } else if (activeRefresh) {
      return refreshToken(data?.refreshToken, true);
    }
  }

  async function checkLocalStorage() {
    let dataT = getParamTokenData();
    if (!isObjectEmpty(dataT) && !isObjectEmpty(dataT?.refreshToken)) {
      refreshToken(dataT?.refreshToken, true);
      return;
    }
    if (isObjectEmpty(dataT)) dataT = getLocalStorageItem("tokenData");
    if (!isObjectEmpty(dataT)) {
      checkTokenData(dataT);
    } else {
      setLocalStorageItem(
        "failTokenData",

        "tokenData: " +
          JSON.stringify(getLocalStorageItem("tokenData")) +
          "; ParamTokenData: " +
          JSON.stringify(getParamTokenData()) +
          "; href: " +
          window.location.href,
      );
      setLocalStorageItem(
        "failToketDataReason",
        "Не найдены авторизационные данные",
      );
      setTokenData({});
    }
  }

  async function checkTokenData(data) {
    if (
      isObjectEmpty(data?.accessToken) &&
      !isObjectEmpty(data?.refreshToken)
    ) {
      return refreshToken(data?.refreshToken, true);
    }

    let [active, activeSoon, activeRefresh] = decodeTokenTime(data);
    if (active || (active && !activeSoon)) {
      if (!activeSoon) {
        return refreshToken(data?.refreshToken, true);
      } else {
        return checkAndSetTokenData(data);
      }
    } else if (activeRefresh) {
      return refreshToken(data?.refreshToken, true);
    }
  }

  function decodeTokenTime(data) {
    let active =
      new Date(data?.accessTokenExpires) > new Date(Date.now()) || false;
    let activeSoon =
      new Date(data?.accessTokenExpiresSoon) > new Date(Date.now()) || false;
    let activeRefresh =
      new Date(data?.refreshTokenExpires) > new Date(Date.now()) || false;
    return [active, activeSoon, activeRefresh];
  }

  async function refreshToken(rToken, del) {
    if (rToken) {
      let { data, error } = await refreshMutation({
        variables: {
          input: {
            token: rToken,
          },
        },
      });
      if (error) {
        if (error.networkError) {
          return;
        } else {
          setLocalStorageItem(
            "failTokenData",
            "tokenData: " +
              JSON.stringify(getLocalStorageItem("tokenData")) +
              "; ParamTokenData: " +
              JSON.stringify(getParamTokenData()) +
              "; href: " +
              window.location.href +
              "; data:" +
              JSON.stringify(data) +
              "; error:" +
              JSON.stringify(error),
          );
          setLocalStorageItem(
            "failToketDataReason",
            "Токен обновления некорректный." + error?.message,
          );
          setTokenData({});
        }
        return;
      }
      if (
        data?.auth_extRefreshToken.code === 200 &&
        data?.auth_extRefreshToken?.data?.accessToken
      ) {
        setLocalStorageItem(
          "tokenDataOriginal",
          data?.auth_extRefreshToken?.data,
        );
        let obj = createTokenData(
          data?.auth_extRefreshToken?.data?.accessToken,
          data?.auth_extRefreshToken?.data?.expiresIn,
          data?.auth_extRefreshToken?.data?.refreshToken,
          data?.auth_extRefreshToken?.data?.refreshExpiresIn,
        );

        setTokenData(obj);
        return;
      }
      if (
        data?.auth_extRefreshToken.code === 401 ||
        data?.auth_extRefreshToken.code === 500
      ) {
        setLocalStorageItem(
          "failTokenData",
          "tokenData: " +
            JSON.stringify(getLocalStorageItem("tokenData")) +
            "; ParamTokenData: " +
            JSON.stringify(getParamTokenData()) +
            "; href: " +
            window.location.href +
            "; data:" +
            JSON.stringify(data),
        );
        setLocalStorageItem(
          "failToketDataReason",
          "TokenRefresh or clientID not correct." + error?.message,
        );
        setTokenData({});
      }
    }
  }

  async function checkAndSetTokenData(tokenD, dontSet = false) {
    if (isObjectEmpty(tokenD)) return;

    let success = await checkToken(tokenD?.accessToken);
    if (success === true) {
      // console.log("Успешная проверка на активность токена");
      if (!dontSet) setTokenData(tokenD);
    } else if (success === false) {
      if (tokenD?.refreshToken) refreshToken(tokenD?.refreshToken, true);
      else {
        setLocalStorageItem(
          "failTokenData",
          "paramToken: " +
            JSON.stringify(getParamToken()) +
            "; tokenData: " +
            JSON.stringify(getLocalStorageItem("tokenData")) +
            "; ParamTokenData: " +
            JSON.stringify(getParamTokenData()) +
            "; href: " +
            window.location.href +
            "; tokenCheck: " +
            tokenD?.accessToken,
        );
        setLocalStorageItem(
          "failToketDataReason",
          "Проверка токена по tokenData не была пройдена, refreshToken отсутствует.",
        );
        setLogined(false);
      }
    }
  }

  async function checkToken(token) {
    let { data, error } = await selfDataQuery({
      variables: {
        token: token,
      },
    });

    if (error) {
      if (error.networkError) {
        return undefined;
      } else return false;
    }
    if (data) {
      return true;
    }

    return undefined;
  }

  return children;
}
