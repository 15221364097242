import { useQuery } from "@apollo/client";

import { REJECT_REASONS } from "components/gql/gql_queries";
import { logGQLError } from "data/functions";

export function RejectReasonsQuery() {
  const queryName = "RejectReasons";
  const query = useQuery(REJECT_REASONS, {
    onError: (e) => {
      logGQLError(queryName, e);
    },
  });

  return query;
}
