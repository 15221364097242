import { GetData } from "contexts/DataContext";
import { showMainScreen } from "data/mobapp_functions";
import { DesktopMainPage } from "pages/main/desktop/DesktopMain";
import { MobileMainPage } from "pages/main/mobile/MobileMain";
import React from "react";

function MainPage() {
  const { dataObj } = GetData();
  React.useEffect(() => {
    if (dataObj?.clientID) showMainScreen(dataObj?.clientID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <React.Fragment>
      {dataObj?.deviceWidth?.isMobile ? (
        <MobileMainPage />
      ) : (
        <DesktopMainPage />
      )}
    </React.Fragment>
  );
}

export default MainPage;
