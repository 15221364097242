export async function uploadFile(presignUrl, file) {
  const myHeaders = new Headers();
  myHeaders.append("X-Amz-Tagging", presignUrl?.xAmxTagging);

  var data = new FormData();
  data.append("file", file);
  const requestOptions = {
    body: file,
    headers: myHeaders,
    method: "PUT",
  };

  return await fetch(presignUrl?.url, requestOptions)
    .then((response) => {
      return response.text();
    })
    .then((result) => {
      // console.log(result);
      return true;
    })
    .catch((error) => {
      console.error(error);
      return false;
    });
}

export async function getActions() {
  const requestOptions = {
    method: "GET",
    // mode: "no-cors",
    redirect: "follow",
  };

  return await fetch(
    "https://cloud.5-systems.ru/5systems/actions.json",
    requestOptions,
  )
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((error) => {
      console.error(error?.message);
      return [];
    });
}

export async function getServices() {
  const requestOptions = {
    method: "GET",
    // mode: "no-cors",
    redirect: "follow",
  };

  return await fetch(
    "https://cloud.5-systems.ru/5systems/services.json",
    requestOptions,
  )
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((error) => {
      console.error(error?.message);
      return [];
    });
}

export async function adminLogin(login, password) {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({
    password: password,
    username: login,
  });

  const requestOptions = {
    body: raw,
    headers: myHeaders,
    method: "POST",
    mode: "no-cors",
    redirect: "follow",
  };

  return await fetch(
    "https://cloud.5-systems.ru/5systems/redirect.php?url=https://api.5systems.ru/auth/v1/oidc/login&method=POST",
    requestOptions,
  )
    .then((response) => {
      return response.text();
    })
    .then((result) => {
      return result;
    })
    .catch((error) => {
      console.error(error);
      return {};
    });
}
