import { Add, Refresh } from "@mui/icons-material";
import {
  Avatar,
  CircularProgress,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { CarListQuery } from "./gql/queris/GetCarList";

export function CarLoadBlock() {
  // const navigate = useNavigate();
  const [carRefetch, carVars] = CarListQuery();
  return (
    <React.Fragment>
      <ListItem
        onClick={() => carRefetch()}
        sx={{
          "&:hover": {
            backgroundColor: "rgb(0 0 0 / 5%)",
            cursor: "pointer",
          },
        }}
      >
        <ListItemAvatar>
          <Avatar
            sx={{
              background: "transparent",
              boxShadow: "inset 0px 0px 2px #00000026",
            }}
          >
            {carVars?.loading ? (
              <CircularProgress size={20} />
            ) : (
              <Refresh color="error" />
            )}
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={carVars?.loading ? "Загрузка" : "Не удалось загрузить"}
        />
      </ListItem>
    </React.Fragment>
  );
}
