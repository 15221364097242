import { Box, Button, Divider, Paper, Typography } from "@mui/material";
import { DrawerBlock } from "components/DrawerBlock";
import { ExtPaymentCreateMutation } from "components/gql/mutations/PaymentCreate";
import { GetAlertData } from "contexts/AlertContext";
import { GetCInfo } from "contexts/CompanyContext";
import { GetData } from "contexts/DataContext";
import { isActiveChannel, isObjectEmpty } from "data/functions";
import sberLogo from "data/images/sber.png";
import { ReactComponent as SbpLogo } from "data/images/sbp.svg";
import tinkoffLogo from "data/images/tinkoff.png";
import React from "react";
import { Link } from "react-router-dom";

export function PayButton(props) {
  const { cInfo } = GetCInfo();
  const { dataObj } = GetData();
  const [openFilter, setOpenFilter] = React.useState(false);
  const [filterArray, setFilterArray] = React.useState([]);
  const [paymentCreateQuery] = ExtPaymentCreateMutation();
  const { setAlert } = GetAlertData();
  let findSber = isActiveChannel(cInfo?.channels, "Sber_Payment");
  let findTinkoff = isActiveChannel(cInfo?.channels, "Tinkoff_Payment");

  React.useEffect(() => {
    let arr = [];
    let findSber = isActiveChannel(cInfo?.channels, "Sber_Payment");
    if (findSber) {
      arr = [
        ...arr,
        {
          icon: sberLogo,
          name: "Сбербанк",
        },
      ];
    }
    let findTinkoff = isActiveChannel(cInfo?.channels, "Tinkoff_Payment");
    if (findTinkoff) {
      arr = [
        ...arr,
        {
          icon: tinkoffLogo,
          name: "Т‑Банк",
        },
      ];
    }
    setFilterArray(arr);
  }, [cInfo?.channels]);

  async function getUrl(uuid, orderID) {
    if (!uuid || !orderID) return;
    let { data } = await paymentCreateQuery({
      variables: {
        companyUuid: uuid,
        orderId: orderID,
      },
    });
    if (
      data?.export_paymentCreate?.success &&
      data?.export_paymentCreate?.result?.orderUrl
    ) {
      window.open(data?.export_paymentCreate?.result?.orderUrl, "_blank");
    } else {
      setAlert("Оплата сейчас недоступна.\nПопробуйте позже", "warning");
    }
  }
  return (
    <React.Fragment>
      {(findSber || findTinkoff) &&
        // dataObj?.includeBeta &&
        !isObjectEmpty(props?.docItem?.payDebt) && (
          <Box
            sx={{
              minHeight: "50px",
              overflow: "auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              onClick={() => getUrl(cInfo?.UUID, props?.orderID)}
              // component={Link}
              // fullWidth={dataObj?.deviceWidth?.isMobile ? true : false}
              sx={{ textTransform: "none", width: "70%" }}
              target="_blank"
              // to="https://qr.nspk.ru/BD20001DPB17VJ3O8L5BFC1AK6F9HPT8?type=02&bank=100000000004&sum=1000&cur=RUB&crc=9970"
              variant="contained"
            >
              <SbpLogo style={{ height: "24px", width: "24px" }} /> К оплате{" "}
              {props?.docItem?.payDebt} руб.
            </Button>
          </Box>
        )}
      <DrawerBlock
        component={<DrawerEl />}
        height={"auto"}
        itemArray={filterArray || []}
        onClickItemFunc={(el = "") => {
          setOpenFilter(false);
          console.log(el);
        }}
        onCloseFunc={() => {
          setOpenFilter(false);
        }}
        open={openFilter}
        showFull={false}
        title="Оплатить по СБП"
      />
    </React.Fragment>
  );
}

const DrawerEl = (props) => {
  if (isObjectEmpty(props?.el)) return "";
  return (
    <React.Fragment>
      {props?.el && (
        <Paper
          index={props?.index}
          sx={{
            padding: "0.86rem",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <img
            alt={props?.el?.name}
            onError={(e) => {
              e.target.hidden = true;
            }}
            src={props?.el?.icon}
            style={{
              overflow: "hidden",
              // background: "white",
              width: "45px",
            }}
          />
          <Typography sx={{ padding: "0 0.86rem" }}>
            {props?.el?.name}
          </Typography>
          <Divider />
        </Paper>
      )}
    </React.Fragment>
  );
};
