//https://yandex.ru/dev/maps/jsbox/2.1/placemark/
//https://yandex.ru/dev/maps/jsbox/2.1/balloon_html/
// https://yandex.ru/dev/maps/jsbox/2.1/list_box_layout

import { Box } from "@mui/material";
import { YMaps, withYMaps } from "@pbe/react-yandex-maps";
import { isObjectEmpty } from "data/functions";
import * as React from "react";

export function MapBlockOnePoint(props) {
  const mapComp = React.useMemo(() => {
    return ({ setOK, ymaps }) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const [map, setMap] = React.useState(null);

      // eslint-disable-next-line react-hooks/rules-of-hooks
      const mapRef = React.useRef(null);

      // eslint-disable-next-line react-hooks/rules-of-hooks
      React.useEffect(() => {
        if (!ymaps || !mapRef.current) {
          return;
        }
        ymaps?.ready(() => {
          if (isObjectEmpty(mapRef.current?.children)) {
            let mapN = new ymaps.Map(
              mapRef.current,
              {
                center: [
                  props?.item?.locationLatitude,
                  props?.item?.locationLongitude,
                ] || [0, 0],
                controls: ["zoomControl"],
                zoom: 10,
              },
              {
                geolocationControlFloat: "right",
                // restrictMapArea: true,
                suppressMapOpenBlock: true,
                zoomControlPosition: { left: 10, top: 50 },
                zoomControlSize: "small",
              },
            );
            setMap(mapN);
          }
        });

        // return () => (canceled = true);
      }, [ymaps]);
      // eslint-disable-next-line react-hooks/rules-of-hooks
      React.useEffect(() => {
        if (map && !isObjectEmpty(props?.item)) {
          let placemark = new ymaps.Placemark(
            [props?.item?.locationLatitude, props?.item?.locationLongitude],
            {
              balloonContent: props?.item.adress,
              balloonContentFooter: `<a href="/departments/${props?.item.ID}">Показать</>`,
              balloonContentHeader: props?.item.name,
              iconContent: "",
            },
            {
              balloonAutoPan: false,
              balloonCloseButton: true,
              openBalloonOnClick: true,
            },
          );
          map.geoObjects.add(placemark);
          if (map.geoObjects.getBounds())
            map
              .setBounds(map.geoObjects.getBounds(), { checkZoomRange: true })
              .then(function () {
                if (map.getZoom() > 15) map.setZoom(15);
              });
          if (map && map.controls.get("zoomControl"))
            map.controls.add("zoomControl", {
              float: "none",
              position: {
                bottom: "50px",
                right: "30px",
              },
              size: "small",
            });
          let zoom = map.controls.get("zoomControl");
          zoom.events.add("click", function (e) {
            if (!map.behaviors.isEnabled("drag")) {
              map.behaviors.enable("drag");
            }
          });
          // if (departmentArray.length === 1) placemark.balloon.open();
        }

        if (map) {
          if (map.behaviors.isEnabled("scrollZoom")) {
            map.behaviors.disable("scrollZoom");
          }
          if (map.behaviors.isEnabled("drag")) {
            map.behaviors.disable("drag");
          }
          if (map.behaviors.isEnabled("dblClickZoom")) {
            map.behaviors.disable("dblClickZoom");
          }
          if (map.behaviors.isEnabled("multiTouch")) {
            map.behaviors.disable("multiTouch");
          }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [map]);

      return (
        <div
          ref={mapRef}
          style={{
            width: "98%",
            height: "240px",
            display: "flex",
            justifyContent: "center",
          }}
        />
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Map = React.useMemo(() => {
    return withYMaps(mapComp, true, ["Map", "Placemark"]);
  }, [mapComp]);

  function haveBaloon(item) {
    if (isObjectEmpty(item)) return false;
    return (
      !isObjectEmpty(item?.locationLatitude) &&
      !isObjectEmpty(item?.locationLongitude)
    );
  }

  return (
    <React.Fragment>
      {!isObjectEmpty(props?.item) && haveBaloon(props?.item) && (
        <Box
          sx={{
            position: "relative",
            marginTop: "6px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <YMaps
            onError={(e) => console.log(e)}
            preload={true}
            query={{
              apikey: "814fa36a-a896-4ffa-971d-1cd05a72aa65",
              load: "package.full",
            }}
          >
            <Map />
          </YMaps>
        </Box>
      )}
    </React.Fragment>
  );
}
