import { useMutation } from "@apollo/client";
import { EXT_REFRESH_TOKEN } from "components/gql/gql_queries";
import { consoleBeauty, logGQLError } from "data/functions";

export function ExtRefreshTokenMutation() {
  const queryName = "ExtRefreshToken";
  const [mutation, variables] = useMutation(EXT_REFRESH_TOKEN, {
    onCompleted: (data) => {
      if (
        data?.auth_extRefreshToken.code === 200 &&
        data?.auth_extRefreshToken?.data?.accessToken
      ) {
        consoleBeauty("Успешное обновление токена", "GraphQL", "darkgreen");
      } else {
        logGQLError(queryName, data?.auth_extRefreshToken);
      }
    },
    onError: (e) => {
      logGQLError(queryName, e);
    },
  });
  return [mutation, variables];
}
