import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  useTheme,
} from "@mui/material";

import { PageTitle } from "components/PageTitle";
import { GetData } from "contexts/DataContext";
import React, { useEffect, useState } from "react";

import { Settings } from "@mui/icons-material";
import { Patterns } from "components/StorageDialog";
import { localeTranslate, mockMessage } from "data/models";
import { cancel, format, register, render } from "timeago.js";
import { ChatBody } from "./ChatBody";
import { ChatInput } from "./ChatInput";

function Chat() {
  const [messages, setMessages] = useState([]);
  const [open, setOpen] = useState(false);
  register("my-locale", localeTranslate);
  const { dataObj } = GetData();
  React.useEffect(() => {
    setMessages(mockMessage);
  }, [mockMessage]);
  const theme = useTheme();

  function addMessage(value, type, name = "") {
    let obj = {
      date: format(new Date(), "my-locale"),
      dateString: format(new Date(), "my-locale"),
      position: "right",
      type: type,
    };
    let status = {
      click: true,
      download: true,
    };

    if (type === "text") {
      obj.text = value;
    }
    if (type === "file") {
      obj = {
        ...obj,
        data: {
          id: "file",
          status: status,
          uri: value,
        },
        text: name ? name : "filename",
      };
    }
    if (type === "photo") {
      obj = {
        ...obj,
        data: {
          uri: value,
        },
      };
    }
    if (type === "video") {
      obj = {
        ...obj,
        data: {
          status: status,
          videoURL: value,
        },
      };
    }
    if (type === "audio") {
      obj = {
        ...obj,
        data: {
          audioURL: value,
        },
      };
    }
    setMessages((e) => [...e, obj]);
  }
  const images = require.context("data/patterns", true);
  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
          position: "relative",
          maxWidth: "100%",
          "::before": {
            content: `""`,
            position: "absolute",
            width: "100%",
            height: "100%",
            zIndex: "-1",
            background: `url(${dataObj?.chatPattern ? dataObj?.chatPattern : images("./pattern11.svg")}) center center/contain repeat`,
            // background: `url(${images("./pattern11.svg")}) center center/contain repeat`,
            opacity: dataObj?.themeMode === "light" ? 0.1 : 0.4,
          },
          // background: dataObj?.chatPattern
          //   ? `linear-gradient(to right, rgba(255,255,255, 0.2) 0 100%), url(${dataObj?.chatPattern}) center center/contain repeat`
          //   : undefined,
          // background: `url(${require("data/patterns/pattern1.png")}) center center/contain repeat`,
        }}
      >
        <PageTitle title="Чат" />

        {dataObj?.includeBeta && (
          <Box
            sx={{
              position: "absolute",
              top: dataObj?.deviceWidth?.isMobile ? "6px" : "16px",
              right: dataObj?.deviceWidth?.isMobile ? "6px" : "16px",
            }}
          >
            <IconButton onClick={() => setOpen(true)} sx={{ opacity: "0.2" }}>
              <Settings />
            </IconButton>
          </Box>
        )}

        <Box
          sx={{ display: "flex", flexDirection: "column", overflow: "auto" }}
        >
          <ChatBody messages={messages} />
          <ChatInput addMessage={addMessage} />
        </Box>
      </Box>
      <Dialog
        PaperProps={
          {
            // style: {
            //   width: "90vw",
            //   maxWidth: "90vw",
            //   minHeight: "90vh",
            // },
          }
        }
        hideBackdrop={true}
        onClose={() => setOpen(false)}
        open={open}
      >
        <DialogContent
          sx={{
            padding: "0 0.86em",
            overflowY: "scroll",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box sx={{ overflow: "auto" }}></Box>
          <Patterns hide={true} />
          <Button onClick={() => setOpen(false)}>OK</Button>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default Chat;
