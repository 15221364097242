import { useLazyQuery } from "@apollo/client";
import { GET_COMPANY_STYLE } from "components/gql/gql_queries";
import { logGQLError } from "data/functions";

export function GetCompanyExtDataQuery() {
  const queryName = "GetCompanyStyle";
  const [refetch, variables] = useLazyQuery(GET_COMPANY_STYLE, {
    // errorPolicy: "all",
    fetchPolicy: "no-cache",
    onError: (e) => {
      logGQLError(queryName, e);
    },
  });
  return [refetch, variables];
}
