import React from "react";

import { Box, Button } from "@mui/material";

import { GetCInfo } from "contexts/CompanyContext";
import { GetData } from "contexts/DataContext";
import { isObjectEmpty } from "data/functions";
import { ReviewsDialog } from "pages/departments/ReviewDialog";
import { useNavigate } from "react-router-dom";
import { MapButton } from "./MapButton";

export function DepartmentButtons(props) {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const { dataObj } = GetData();
  const { cInfo, setCInfo } = GetCInfo();
  const handleSubmit = async (item, state, index) => {
    if (!isObjectEmpty(cInfo?.regions) && props?.item?.region) {
      let find = cInfo?.regions.find(
        (reg) => reg?.name === props?.item?.region,
      );
      await setCInfo(find, "preferedRegion");
    }
    navigate("/request", { state: { departmentItem: props?.item } });
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
        margin: "0.5rem",
        boxShadow: "none",
        flexWrap: "wrap",
        gap: "0.2rem",
      }}
    >
      <Button disableRipple onClick={handleSubmit} variant="outlined">
        Записаться
      </Button>
      {dataObj.includeBeta && (
        <Button
          disableRipple
          // onClick={handleSubmit}
          onClick={(e) => {
            if (props?.item?.reviews && props?.item?.reviews?.length > 0)
              setOpen(true);
            else {
              alert("Отзывов нет..");
            }
          }}
          variant="outlined"
        >
          Отзывы
        </Button>
      )}

      <MapButton item={props?.item} />
      <ReviewsDialog
        reviews={props?.item?.reviews}
        setOpen={setOpen}
        status={open}
      />
    </Box>
  );
}
